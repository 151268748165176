.HeaderSubcategories {
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 70;
}

.HeaderSubcategories-inner {
    background-color: white;
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07)
    ;
}

.HeaderSubcategories-item {
    display: grid;
    grid-template-columns: 0.65fr 1.35fr;
    position: relative;
}

.HeaderSubcategories-parent {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &::before {
        content: '';
        opacity: 0.4;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-right: 1px solid var(--product-category-color, #{$color-orange-primary});
    }

    @include mq($from: 1240px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.HeaderSubcategories-child {
    position: relative;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;

    @include mq($from: 1024px) {
        padding-right: 4rem;
        padding-left: 4rem;
    }

    @include mq($from: 1240px) {
        padding-top: 4rem;
        padding-right: 8rem;
        padding-bottom: 4rem;
        padding-left: 8rem;
    }
}

.HeaderSubcategories-nav {}

.HeaderSubcategories-navList {
    overflow: hidden;
}

.HeaderSubcategories-navItem {
    margin: 0;
    padding-left: 2rem;
    border-left: 3px solid transparent;
    color: $text-color;
    transition: color 200ms, border-color 200ms;

    &:hover,
    &.is-active {
        border-color: var(--product-category-color, #{$color-orange-primary});
        color: var(--product-category-color, #{$color-orange-primary});

        .HeaderSubcategories-navLink .Icon {
            opacity: 1;
            transform: translateX(0);
        }

        .HeaderSubcategories-navLabel {
            transform: translateX(2.5rem);
        }
    }

    @include mq($from: 1024px) {
        padding-left: 4rem;
        border-left-width: 5px;

        &:hover,
        &.is-active {

            .HeaderSubcategories-navLabel {
                transform: translateX(3rem);
            }
        }
    }

    @include mq($from: 1240px) {
        padding-left: 8rem;
    }
}

.HeaderSubcategories-navLink {
    cursor: pointer;
    display: block;
    position: relative;
    padding-top: 0.5rem;
    padding-right: 3rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    color: $text-color;
    text-decoration: none;

    .Icon {
        opacity: 0;
        position: absolute;
        top: 0.75rem;
        left: 0;
        transform: translateX(-1rem);
        transition: opacity 200ms, transform 300ms;
    }

    &:hover {

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 1;
            width: 75%;
            height: 500%;
            clip-path: polygon(0 50%, 100% 100%, 100% 0);
            transform: translateY(-50%);
        }
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: var(--product-category-color, #{$color-orange-primary});
        text-decoration: none;
    }

    @include mq($from: 1024px) {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .Icon {
            top: 1.375rem;
            transform: translateX(-2rem);
        }
    }
}

.HeaderSubcategories-navLabel {
    display: block;
    transition: transform 200ms;
    transition-delay: 35ms;
}

.HeaderSubcategories-subnav {
    max-width: calc(100% - 17rem);

    @include mq($from: 1024px) {
        max-width: calc(100% - 24rem);
    }
}

.HeaderSubcategories-subnavList {}

.HeaderSubcategories-subnavItem {
    margin: 0;

    &:hover,
    &.is-active {

        .HeaderSubcategories-image {
            display: block;
        }
    }
}

.HeaderSubcategories-subnavLink {
    cursor: pointer;
    display: inline-flex;
    position: relative;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    color: $text-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: var(--product-category-color, #{$color-orange-primary});
        text-decoration: none;
    }

    @include mq($from: 1024px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.HeaderSubcategories-image {
    display: none;
    position: absolute;
    top: 3rem;
    right: 2rem;
    width: 100%;
    max-width: 15rem;

    .Media-object,
    .ImageWrapper {

        > img {
            width: 100%;
        }
    }

    @include mq($from: 1024px) {
        right: 4rem;
        max-width: 20rem;
    }

    @include mq($from: 1240px) {
        top: 5rem;
        right: 8rem;
    }
}

.HeaderSubcategories--enter {
    transition: visibility 600ms cubic-bezier(0.165, 0.85, 0.45, 1), opacity 300ms cubic-bezier(1, 0, 0.35, 1);
    transition-delay: 300ms;
}

.HeaderSubcategories--leave {
    transition: visibility 300ms cubic-bezier(0.165, 0.85, 0.45, 1), opacity 300ms cubic-bezier(1, 0, 0.35, 1);
}

.HeaderSubcategories--enter-end,
.HeaderSubcategories--leave-start {
    visibility: visible;
    opacity: 1;
}

.HeaderSubcategories--enter-start,
.HeaderSubcategories--leave-end {
    visibility: hidden;
    opacity: 0;
}

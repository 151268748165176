.Nav--sharer {
    display: flex;
    align-items: center;

    .Nav-label {
        margin-right: 0.5rem;
        color: $color-orange-primary;
        font-weight: 600;
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    .Nav-item {
        margin-left: 1rem;
    }

    .Icon {
        width: 3.2rem;
        height: 3.2rem;
    }
}

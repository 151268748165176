.Header {
    position: relative;
}

.Header-top {
    position: relative;
    z-index: 81;
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
    color: white;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    background-color: $color-orange-primary;

    @include mq($from: 768px) {
        text-align: left;

        > .Container {
            display: flex;
            justify-content: space-between;
        }
    }
}

.Header-topRight {
    display: flex;

    > * + * {
        position: relative;
        margin-left: 2rem;
        padding-left: 2rem;

        &::before {
            content: '';
            position: absolute;
            top: -0.5rem;
            bottom: -0.6rem;
            left: 0;
            border-left: 1px solid rgba(white, 0.2);
        }
    }
}

.Header-middle {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include mq($until: 767px) {
        flex-wrap: wrap;
    }

    @include mq($from: 1024px) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

.Header-middleLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Header-middleRight {
    display: flex;
    align-items: center;
    margin-left: auto;

    .LanguageSwitcher {
        color: black;
    }
}

.Header-inner {
    position: relative;
    z-index: 80;
    background-color: white;
}

.Header-bottom {
    position: relative;
    z-index: 70;
    background-color: white;
}

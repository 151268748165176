.BundlesTable-wrapper {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}

.BundlesTable {
    border-right-width: 0;
    border-left-width: 0;
    border-collapse: collapse;
    font-size: 1.2rem;

    tr + tr {
        border-top: 1px solid $color-gray-100;
    }

    td {
        vertical-align: top;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

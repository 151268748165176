.HeaderOverlayToggle {
    @extend %button;
    position: relative;
    top: 2px;
    margin-right: 1rem;
    padding: 0;
    border: 0;
    color: $color-orange-primary;

    .Icon {
        width: 3rem;
        height: 3rem;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color-blue-primary;
        box-shadow: none;
    }

    @include mq($from: 375px) {

        .Icon {
            width: 3.5rem;
            height: 3.5rem;
        }
    }

    @include mq($from: 640px) {
        margin-right: 2rem;
    }

    @include mq($from: 1024px) {
        display: none;
    }
}

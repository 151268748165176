/* Logo */
.Logo {
    flex-shrink: 0;

    svg {
        display: block;
        width: 100%;
        max-width: 8rem;

        @include mq($from: 480px) {
            max-width: 13rem;
        }

        @include mq($from: 1024px) {
            max-width: 17.5rem;
        }
    }
}

.NewsBoxes {
    display: grid;
    gap: 2rem;

    @include mq($from: 480px) {
        gap: 3rem;
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: 768px) {
        gap: 3rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @include mq($from: 1240px) {
        gap: 5rem;
        grid-template-columns: repeat(4, 1fr);
    }
}

.NewsBox {
    --heading-color: #{$text-color};
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    height: 100%;
    border-radius: 1.5rem;
    overflow: hidden;
    color: $text-color;
    text-decoration: none;
    background-color: white;
    box-shadow: 0 4px 20px 10px rgba(black, 0.03);

    &:hover,
    &:focus,
    &:active {
        color: $text-color;
        text-decoration: none;

        .NewsBox-media img {
            opacity: 0.25;
            transform: scale(1.05);
        }

        .NewsBox-mediaIcon {
            opacity: 1;
        }

        .Link--primary {
            --btn-text-color: #{$color-orange-600};
            --btn-icon-bg-color: #{$color-orange-600};
        }

        .Button--primary {
            --btn-border-color: #{$color-orange-600};
            --btn-bg-color: #{$color-orange-600};
        }
    }
}

.NewsBox-body {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    height: 100%;
}

.NewsBox-title {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.4;
    transition: color 200ms;
}

.NewsBox-date {
    color: $color-orange-primary;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.NewsBox-media {
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    border-radius: 1.5rem;
    overflow: hidden;
    background-color: $color-orange-primary;

    img {
        transition: opacity 200ms, transform 200ms;
    }
}

.NewsBox-actions {
    margin-top: auto;
}

* + .NewsBox-actions {
    padding-top: 3rem;
}

@include mq($from: 768px) {

    .NewsBox--horizontal {
        --heading-color: #{$color-gray-900};
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        min-height: 40.8rem;

        .NewsBox-media {
            height: 100%;

            .Media-object,
            .ImageWrapper {
                --image-ratio: 0% !important;
                height: 100%;
            }
        }

        .NewsBox-body {
            padding-top: 4rem;
            padding-right: 4rem;
            padding-bottom: 4rem;
            padding-left: 4rem;
            height: auto;
        }

        .NewsBox-title {
            font-weight: 400;
            font-size: 4rem;
            line-height: 1.1;
        }

        @include mq($from: 1240px) {

            .NewsBox-body {
                justify-content: center;
                padding-top: 7rem;
                padding-right: 8rem;
                padding-bottom: 7rem;
                padding-left: 8rem;
            }
        }
    }
}

.NewsHighlight {

    @include mq($from: 1024px) {

        .Intro {
            margin-bottom: 5rem;
        }
    }
}

.NewsBox-mediaIcon {
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    color: $color-orange-primary;
    background-color: white;
    transform: translate(-50%, -50%);
    transition: opacity 200ms;
}

.SiteSearch {
    width: 100%;
}

.SiteSearch-form {
    position: relative;
    width: 100%;
}

.SiteSearch-field {
    padding-top: 1.3rem;
    padding-right: 1rem + 4.5rem;
    padding-bottom: 1.3rem;
    padding-left: 2.5rem;
    border-radius: 100px;
    color: $color-orange-primary;

    &::placeholder {
        color: $color-orange-primary;
        font-weight: 300;
    }

    &:focus {
        box-shadow: 0 0 0 2px rgba($color-orange-primary, 0.1);
    }
}

.SiteSearch-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 0;
    border-color: $color-orange-primary;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 100%;
    color: white;
    background-color: $color-orange-primary;

    .Icon {
        width: 2rem;
        height: 2rem;
    }

    &:hover,
    &:focus,
    &:active {
        border-color: $color-blue-primary;
        color: white;
        background-color: $color-blue-primary;
    }
}

.SiteSearch--header {
    order: 3;
    flex: 1 1 100%;
    margin-top: 2rem;
    padding-top: 2rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -2rem;
        left: -2rem;
        border-top: 1px solid rgba($color-orange-primary, 0.1);
    }

    @include mq($from: 768px) {
        order: inherit;
        flex: 1 1 0;
        margin-top: 0;
        margin-left: 2rem;
        padding-top: 0;
        max-width: 51rem;

        &::before {
            content: none;
        }
    }

    @include mq($from: 920px) {
        margin-left: 4rem;
    }

    @include mq($from: 1024px) {
        margin-right: 2rem;
    }

    @include mq($from: 1240px) {
        margin-left: 8rem;
    }
}

.SiteSearch--category {
    margin-bottom: 4.5rem;
}

.SiteSearch-results {
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 50%;
    z-index: 100;
    margin-left: -50vw;
    width: 100vw;
}

.SiteSearch-resultsInner {
    background-color: white;
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07)
    ;
}

.SiteSearch-resultsLayout {
    display: grid;
    gap: 8rem;
    position: relative;
    padding-top: 3rem;
    padding-bottom: 5rem;

    @include mq($from: 1024px) {
        grid-template-columns: minmax(0, 24rem) minmax(0, 1fr);
    }

    @include mq($from: 1360px) {
        gap: 10rem;
        grid-template-columns: minmax(0, 28rem) minmax(0, 1fr);
    }
}

.SiteSearch-results .ProductBox-image {

    @include mq($from: 580px) {
        padding-top: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
    }
}

.SiteSearch-results .ProductBox-title {
    font-size: 1.4rem;
}

.SiteSearch-resultsTitle {
    color: $color-orange-primary;
}

.SiteSearch-resultsCategories {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.4rem;

    > li {

        > a {
            color: $text-color;
            text-decoration: none;

            &:hover {
                color: $color-orange-primary;
            }
        }
    }

    @include mq($from: 1360px) {
        font-size: 1.6rem;
    }
}

.SiteSearch-showALlResults {
    color: $text-color;
    text-decoration: underline;

    &:hover {
        color: $color-orange-primary;
    }
}

.SiteSearch-resultsBody {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -4rem;
        border-left: 1px solid rgba(black, 0.05);

        @include mq($from: 1360px) {
            left: -5rem;
        }
    }
}

.SiteSearch-button--search {

    @include mq($until: 1023px) {

        &,
        &.u-hidden {
            display: flex !important;
        }
    }
}

.SiteSearch-button--clear {

    @include mq($until: 1023px) {
        display: none !important;
    }
}

.SiteSearch-results {

    @include mq($until: 1023px) {
        display: none !important;
    }
}

.ProductBoxes--siteSearch {
    margin-top: -2rem !important;
    margin-left: -2rem !important;

    @include mq($from: 1200px) {
        margin-top: -4rem !important;
        margin-left: -4rem !important;
    }

    .ProductBoxes-item {

        @include mq($from: 480px) {
            width: calc(100% / 2);
        }

        @include mq($from: 768px) {
            width: calc(100% / 3);
        }

        @include mq($from: 1200px) {
            padding-top: 4rem;
            padding-left: 4rem;
            width: calc(100% / 4);
        }
    }

    @include mq($until: 1199px) {

        > *:last-child {
            display: none !important;
        }
    }
}

.Search-results {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include mq($from: 1024px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

.Search-results + .Search-results {
    border-top: 1px solid $color-orange-primary;
}

.Search-title {
    margin-bottom: 4rem;
    color: $color-orange-primary;
}

.Search-categories {
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 75rem;
}

.Search-categoriesTitle {
    color: $text-color;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
}

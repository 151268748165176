.cb-Jobs {

    @include mq($from: 1024px) {

        .Intro {
            margin-bottom: 5rem;
        }
    }
}

.cb-Jobs-items {
    margin-right: auto;
    margin-left: auto;
    max-width: 90rem;

    > * + * {
        margin-top: 2rem;
    }
}

.cb-Jobs-item {
    display: grid;
    grid-template-columns: 8rem auto;
    border-radius: 1.5rem;
    overflow: hidden;
    color: $text-color;
    text-decoration: none;
    background-color: white;
    box-shadow:
        1.4px 1.8px 2.2px rgba(0, 0, 0, 0.003),
        3.3px 4.3px 5.3px rgba(0, 0, 0, 0.004),
        6.3px 8.1px 10px rgba(0, 0, 0, 0.005),
        11.2px 14.5px 17.9px rgba(0, 0, 0, 0.006),
        20.9px 27.2px 33.4px rgba(0, 0, 0, 0.007),
        50px 65px 80px rgba(0, 0, 0, 0.01)
    ;

    &:hover,
    &:focus,
    &:active {
        color: $text-color;
        text-decoration: none;

        // .cb-Job-image {

        //     .ImageWrapper > img {
        //         transform: scale(1.1);
        //     }
        // }

        // .cb-Job-title {
        //     color: $color-orange-primary;
        // }

        .cb-Job-arrowLink {
            opacity: 1;
        }
    }

    @include mq($from: 480px) {
        grid-template-columns: 12rem auto;
    }

    @include mq($from: 640px) {
        grid-template-columns: 21rem auto;
    }
}

.cb-Job-image {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    overflow: hidden;
    background-color: $color-orange-100;

    .Media-object,
    .ImageWrapper {
        --image-ratio: 0%;
        height: 100%;

        > img {
            transition: transform 200ms;
        }
    }
}

.cb-Job-body {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 2rem;
    padding-right: 7rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    height: 100%;

    @include mq($from: 768px) {
        padding-top: 4rem;
        padding-right: 9.5rem;
        padding-bottom: 4rem;
        padding-left: 4rem;
    }

    @include mq($from: 1024px) {
        padding-left: 6rem;
    }
}

.cb-Job-title {
    color: $color-gray-900;
    transition: color 200ms;
}

.cb-Job-meta {
    display: flex;
    margin-bottom: 0.5rem;
    padding-left: 0;
    list-style: none;
    color: $color-orange-primary;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;

    > li {
        margin: 0;
    }

    > li:not(:last-child) {
        position: relative;

        &::after {
            content: '•';
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }

    @include mq($until: 1024px) {
        font-size: 1.3rem;
    }

    @include mq($until: 768px) {
        font-size: 1.2rem;
    }
}

.cb-Job-inner {
    position: relative;
    top: -4px;
}

.cb-Jobs-actionsWrapper {
    margin-top: 3rem;
    text-align: center;

    @include mq($from: 1024px) {
        margin-top: 5rem;
    }
}

.cb-Jobs-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -1rem;
    margin-right: -1rem;
}

.cb-Jobs-action {
    padding-top: 1rem;
    padding-left: 1rem;
}

.cb-Jobs-actionsTitle {
    color: $color-gray-900;
    font-size: 1.8rem;

    @include mq($until: 1023px) {
        @include font-size(16, false);
    }

    @include mq($until: 768px) {
        @include font-size(14, false);
    }
}

.cb-Job-arrowLink {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    color: $color-orange-primary;
    background-color: $color-orange-100;
    transition: opacity 200ms;

    @include mq($from: 768px) {
        right: 3rem;
        width: 4rem;
        height: 4rem;
    }
}

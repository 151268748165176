.ProductDetail {
    padding-bottom: 4rem;

    @include mq($from: 920px) {
        padding-top: 2rem;
    }

    @include mq($from: 1024px) {
        padding-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        padding-bottom: 11rem;
    }
}

.ProductDetail-layout {

    &.with-grid {

        @include mq($from: 920px) {
            display: grid;
            gap: 4rem;
            align-items: flex-start;

            &.is-media-left {
                grid-template-columns: minmax(0, 0.75fr) minmax(0, 0.925fr);
            }
        }

        @include mq($from: 1024px) {
            gap: 7.5rem;
        }

        @include mq($from: 1240px) {
            gap: 11.5rem;
        }
    }
}

.ProductDetail-media {

    @include mq($until: 767px) {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    .Media-object,
    .ImageWrapper,
    .VideoWrapper {
        border-radius: 2.5rem;

        > img {
            object-fit: contain;
        }
    }
}

.ProductDetailMedia-slider,
.ProductDetailThumbs-slider {
    opacity: 1;
    position: relative;
    transition: opacity 1000ms;
    overflow: hidden;

    &:not(.Slider--initialized) {
        opacity: 0;
        overflow: hidden;
    }
}

.ProductDetailMedia-slider {

    .Media-object,
    .ImageWrapper {

        > img {
            box-sizing: border-box;
            padding: 2rem;
        }
    }
}

.ProductDetailThumbs-slider {
    margin-top: 2rem;

    .Slider-item.is-active {

        .Media-object,
        .ImageWrapper {
            border-color: $color-orange-primary;
        }
    }

    .Media-object,
    .ImageWrapper {
        cursor: pointer;
        border: 1px solid $color-orange-100;
        border-radius: 5px;
        overflow: hidden;
        transition: border-color 200ms;

        > img {
            pointer-events: none;
            box-sizing: border-box;
            padding: 1rem;
        }
    }
}

.ProductDetail-show--mobile {

    @include mq($from: 920px) {
        display: none;
    }
}

.ProductDetail-show--desktop {

    + * {
        margin-top: 2rem;
    }

    @include mq($until: 919px) {
        display: none;
    }
}

.ProductDetail-info {
    display: flex;
    align-items: center;
    margin-top: -2rem;
    padding-left: 0;
    list-style: none;
    color: $color-gray-700;
    font-size: 1.2rem;
    text-transform: uppercase;

    > li {
        display: flex;
        align-items: center;
    }

    > li:not(:first-child) {
        position: relative;
        padding-left: 2rem;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 1rem;
            border-left: 1px solid $color-gray-400;
        }
    }
}

.ProductDetail-downloads {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;
    margin-left: -1rem;
    padding-top: 2rem;

    > * {
        padding-top: 1rem;
        padding-left: 1rem;
    }
}

.ProductDetailVariants {}

.ProductDetailVariants-item {
    display: grid;
    gap: 1rem;
    grid-template-columns: .8fr 1fr .6fr 14rem;
    align-items: center;

    @media (min-width: 57.5em) and (max-width: 71.1875em),
           (max-width: 32.4375em) {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

.ProductDetailVariants-item--header {
    color: $color-gray-700;
    font-weight: 500;
    font-size: 1.3rem;
    text-transform: uppercase;
    word-break: break-word;

    @media (min-width: 57.5em) and (max-width: 71.1875em),
           (max-width: 32.4375em) {
        display: none;
    }
}

.ProductDetailVariants-item--body + .ProductDetailVariants-item--body {
    border-top: 1px solid rgba($color-orange-primary, 0.1);
}

.ProductDetailVariants-item--body {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.ProductDetailVariants-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    //padding-right: 2rem;

    /*@media (min-width: 57.5em) and (max-width: 71.1875em),
           (max-width: 32.4375em) {
        grid-column: span 2 / span 2;
    }*/
}

.ProductDetailVariants-media {
    flex-shrink: 0;
    width: 5rem;

    .Media-object,
    .ImageWrapper {

        > img {
            object-fit: contain;
        }
    }
}

.ProductDetailVariants-quantity {
    //text-align: right;
}

.ProductDetail-description {
    max-height: 20rem;
    overflow: hidden;
}

.ProductDetailThumbs-slider .Slider-items {
    box-sizing: border-box;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);

    @include mq($from: 768px, $until: 919px) {
        grid-template-columns: repeat(8, 1fr);
    }

    @include mq($from: 1200px) {
        grid-template-columns: repeat(8, 1fr);
    }
}

.ProductDetailThumbs-slider .Slider-item {
    margin: 0 !important;
    width: 100% !important;
}

.ProductDetailVariants-promotions {
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    width: 100%;
    border-radius: 3rem;
    background-color: $color-orange-50;

    ul {
        padding-left: 0;
        list-style: none;
    }

    + * {
        margin-top: 1rem;
    }
}

.ProductDetailVariants-price-old {
    margin-right: 0.5rem;
    color: $color-gray-600 !important;
    font-size: 1.2rem;
    text-decoration: line-through;
}

.AccountPage {}

.AccountPage-title {
    margin-bottom: 4rem;
    color: $color-orange-primary;

    @include mq($from: 920px) {
        margin-top: 4rem;
    }

    @include mq($from: 1024px) {
        margin-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        margin-bottom: 9rem;
    }
}

.AccountPageSection-title {
    color: $color-gray-700;
}

.AccountPageSection-grid {
    display: grid;
    gap: 2rem;

    @include mq($from: 640px, $until: 919px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.AccountPageSection-actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;
    margin-left: -1rem;

    > * {
        padding-top: 1rem;
        padding-left: 1rem;
    }
}

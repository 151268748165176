.RegisterStoreHours {
    padding-bottom: 1rem;

    .Form-field--select {
        width: 10rem;
    }

    .Form-field--radio,
    .Form-field--checkbox {
        display: inline-flex;

        .Form-indicator {
            top: 0.2em;
            margin-right: 0;
            margin-left: 0.7rem;
            border-color: transparent !important;
            border-radius: 0;
            width: 2rem;
            height: 2rem;
            background-color: transparent;
            box-shadow: none !important;

            &::before {
                content: '';
                position: absolute;
                top: -0.4rem;
                left: -0.45rem;
                width: 2.6rem;
                height: 2.6rem;
                border: 1px solid $color-orange-primary;
            }
        }
    }
}

.RegisterStoreHours-row {
    display: grid;
    gap: 2rem;
    grid-template-columns: 10rem 1fr;
    min-height: 4rem;

    @include mq($from: 640px) {
        grid-template-columns: 10rem 5rem 1fr;
    }

    @include mq($from: 920px) {
        align-items: center;
    }
}

.RegisterStoreHours-row--header {
    color: $color-blue-primary;
    font-weight: 600;
    font-size: 1.3rem;
    text-transform: uppercase;
}

.RegisterStoreHours-row + .RegisterStoreHours-row {
    margin-top: 1rem;
}

.RegisterStoreHours-col--group {

    > * + * {
        margin-top: 1rem;
    }

    @include mq($from: 920px) {
        display: flex;
        align-items: center;

        > * + * {
            margin-top: 0;
        }
    }
}

.RegisterStoreHours-col--fields {

    @include mq($until: 639px) {
        grid-column: span 2 / span 2;
        padding-bottom: 2rem;
    }
}

.RegisterStoreHours-title {
    color: $color-orange-primary;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.RegisterStoreHours-group {

    > * + * {
        margin-top: 1rem;
    }

    @include mq($from: 400px) {
        display: flex;
        align-items: center;

        > * + * {
            margin-top: 0;
        }
    }
}

.RegisterStoreHours-groupItem {
    display: flex;
    align-items: center;
}

.RegisterStoreHours-prefix {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    width: 4rem;
    text-align: center;

    strong {
        font-weight: 600;
    }
}

.RegisterStoreHours-extra {
    margin-top: 2rem;

    .Form-field--checkbox {

        .Form-indicator {
            margin-right: 1.5rem;
        }

        .Form-label {
            position: relative;
            top: 0.275em;
            margin-bottom: 0;
            font-weight: 300;
            font-size: 1.3rem;
        }
    }
}

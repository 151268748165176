.cb-Team {}

.cb-Team-items {
    display: grid;
    gap: 2rem;

    @include mq($from: 480px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.cb-Team-item {
    --heading-color: #{$color-orange-primary};
    text-align: center;

    .Media-object,
    .ImageWrapper {
        border-radius: 2.5rem;
    }
}

.cb-Team-placeholder {
    position: relative;
    padding-bottom: percentage(3 / 5);
    border-radius: 2.5rem;
    overflow: hidden;
    background-color: $color-blue-primary;
}

.cb-Team-contact {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 400;
    font-size: 1.6rem;
}

.cb-Team-contactLink {
    display: inline-flex;
    align-items: center;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: $color-orange-primary;
        text-decoration: none;

        .cb-Team-contactIcon {
            color: white;
            background-color: $color-orange-600;
        }
    }
}

.cb-Team-contactIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    color: white;
    background-color: $color-orange-primary;
    transition: background-color 200ms;

    .Icon {
        width: 1.25em;
        height: 1.25em;
    }
}

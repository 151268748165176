.AccountOrderLatest {
    display: grid;
    column-gap: 2rem;
    row-gap: 1rem;
    align-items: flex-start;
    position: relative;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    border-radius: 1.5rem;
    color: $color-gray-800;
    background-color: $color-orange-50;

    @include mq($from: 390px) {
        grid-template-columns: minmax(8rem, 17rem) minmax(20rem, 1fr);
    }

    @include mq($from: 640px) {
        column-gap: 4rem;
        grid-template-columns: minmax(12rem, 17rem) minmax(20rem, 1fr) 15rem;
        align-items: center;
    }

    @include mq($from: 1024px) {
        gap: 7rem;
    }
}

.AccountOrderLatest-image {
    padding: 2rem;
    width: 100%;
    border-radius: 1.5rem;
    background-color: white;

    .Media-object {

        > img {
            object-fit: contain;
        }
    }

    @include mq($until: 389px) {
        display: none;
    }

    @include mq($until: 639px) {
        grid-row: span 2 / span 2;
    }
}

.AccountOrderLatest-action {

    @include mq($from: 640px) {
        text-align: right;
    }
}

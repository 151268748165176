.cb-Usps {
    position: relative;
    overflow: hidden;
    color: white;
    background-color: $color-orange-primary;

    &.with-image {
        text-shadow: 1px 1px 3px rgba(black, 0.2);
    }

    > .Container {
        position: relative;
    }
}

.cb-Usps-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cb-Usp-item {
    flex: 0 1 auto;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    text-align: center;

    @include mq($from: 1024px) {
        flex: 1 1 20%;
    }
}

.cb-Usp-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    width: 7rem;
    height: 7rem;
    border-radius: 100%;
    color: white;
    background-color: $color-orange-primary;

    .Icon {
        width: 3em;
        height: 3em;
    }

    @include mq($from: 1024px) {
        width: 9.5rem;
        height: 9.5rem;

        .Icon {
            width: 4em;
            height: 4em;
        }
    }
}

.cb-Usps.without-image {

    .cb-Usp-icon {
        color: $color-orange-primary;
        background-color: white;
    }
}

.cb-Usp-title {
    font-weight: 500;
    font-size: 1.4rem;

    @include mq($from: 768px) {
        font-size: 1.6rem;
    }

    @include mq($from: 1024px) {
        font-size: 1.8rem;
    }
}

.cb-Usps-image {
    opacity: 0.35;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .Media-object,
    .ImageWrapper {
        --image-ratio: 0% !important;
        height: 100%;
    }
}

.cb-Usp-body {
    margin-right: auto;
    margin-left: auto;
    max-width: 24rem;
}

.Nav--breadcrumbs {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    text-transform: uppercase;

    .Nav-item:not(:last-child) {
        position: relative;
        padding-right: 2rem;

        &::before {
            content: '›';
            opacity: 0.65;
            position: absolute;
            top: 0.25rem;
            right: 0.75rem;
            color: var(--breadcrumbs-color, #{$color-orange-primary});
        }
    }

    .Nav-link {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        color: var(--breadcrumbs-color, #{$color-orange-primary});
        text-decoration: none;

        .Icon {
            top: 0;
            width: 1.75rem;
            height: 1.75rem;
            fill: var(--breadcrumbs-color, #{$color-orange-primary});
            transition: fill 200ms;
        }

        &:focus,
        &:hover,
        &:active {
            color: var(--breadcrumbs-color, #{$color-orange-primary});
            text-decoration: none;

            .Nav-label {
                opacity: 1;
            }

            .Icon {
                fill: var(--breadcrumbs-color, #{$color-orange-primary});
            }
        }
    }

    .Nav-item.is-active .Nav-link {
        color: var(--breadcrumbs-color, #{$color-orange-primary});
        text-decoration: none;
    }

    @include mq($from: 768px) {
        margin-top: 1.5rem;
    }

    @include mq($from: 1024px) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

.Nav-label {
    opacity: 0.65;
    transition: opacity 200ms;
}

.LanguageSwitcher {
    position: relative;
    z-index: 1;
    color: white;

    &:not(.LanguageSwitcher--mobile):hover,
    &.LanguageSwitcher--mobile.is-open {

        .LanguageSwitcher-icon {
            transform: rotate(-180deg);
        }

        .LanguageSwitcher-listWrapper {
            display: block;
        }
    }
}

.LanguageSwitcher-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.LanguageSwitcher-icon {
    position: relative;
    top: 1px;
    margin-left: 0.5rem;
    transition: transform 200ms;

    .Icon {
        width: 1.25rem;
        height: 1.25rem;
    }

    &.is-active {
        transform: rotate(-180deg);
    }
}

.LanguageSwitcher-listWrapper {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    padding-top: 0.5rem;
}

.LanguageSwitcher-list {
    background-color: $color-blue-primary;
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07)
    ;
}

.LanguageSwitcher-item + .LanguageSwitcher-item {
    border-top: 1px solid rgba(white, 0.2);
}

.LanguageSwitcher-link {
    padding-top: 0.6rem;
    padding-right: 1.2rem;
    padding-bottom: 0.7rem;
    padding-left: 1.2rem;
    color: white;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: white;
        text-decoration: none;
        background-color: $color-blue-700;
    }
}

/* Structure */

// Wrapper
.#{$structure-namespace}Wrapper {
    @include grid(100%, auto 1fr auto);
    grid-template-areas: "header" "main" "footer";
    min-height: 100%;
}

// Header
.#{$structure-namespace}Header {
    // @include position(relative, $z: 3);
    grid-area: header;
}

// Main
.#{$structure-namespace}Main {
    // @include position(relative, $z: 2);
    grid-area: main;
}

// Footer
.#{$structure-namespace}Footer {
    // @include position(relative, $z: 1);
    grid-area: footer;
}

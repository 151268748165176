.AccountOrderHistory {

    .Pagination {
        padding-top: 4rem;
    }
}

.AccountOrderHistory-item {
    display: grid;
    row-gap: 0.5rem;
    column-gap: 2rem;
    grid-template-columns: 1fr 15rem;
    align-items: center;
    position: relative;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    border-radius: 1.5rem;
    color: $color-gray-800;
    background-color: $color-orange-50;

    @include mq($from: 640px) {
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr) 15rem;
        padding-left: 4rem;
    }
}

.AccountOrderHistory-item--header {
    color: $color-orange-primary;
    font-weight: 500;
    font-size: 1.6rem;
    background-color: transparent;

    @include mq($until: 639px) {
        display: none;
    }
}

.AccountOrderHistory-column {

    @include mq($until: 639px) {
        grid-column: 1;
    }
}

.AccountOrderHistory-action {
    text-align: right;

    @include mq($until: 639px) {
        grid-column: 2;
        grid-row: 1 / 3;
    }
}

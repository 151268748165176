.CheckoutSuccess {
    margin-bottom: 4rem;

    @include mq($from: 1024px) {
        margin-top: 4rem;
        margin-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        margin-bottom: 11rem;
    }
}

.CheckoutSuccess-layout {
    display: grid;
    gap: 4rem;

    @include mq($from: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.CheckoutSuccess-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    height: 100%;
    min-height: 32rem;
    overflow: hidden;
    border-radius: 1.5rem;

    .Media-object,
    .ImageWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

    }
}

.CheckoutSuccess-boxBody {
    position: relative;
    z-index: 1;
    padding: 4rem;

    .Bravo {
        color: $color-blue-primary;
        font-weight: 400;
    }
}

.lf-Text {
    --heading-color: #{$color-orange-primary};

    h2, h3, h4 {
        --stack-spacing: 3rem;

        @include mq($from: 768px) {
            --stack-spacing: 4rem;
        }
    }

    h2 {
        @include font-size($heading-size-3, $heading-spacing-3);

        @include mq($until: 1023px) {
            @include font-size(22, $heading-spacing-3);
        }

        @include mq($until: 768px) {
            @include font-size(18, $heading-spacing-3);
        }
    }

    h3 {
        @include font-size($heading-size-4, $heading-spacing-4);

        @include mq($until: 1023px) {
            @include font-size(22, $heading-spacing-4);
        }

        @include mq($until: 768px) {
            @include font-size(18, $heading-spacing-4);
        }
    }

    h4 {
        @include font-size($heading-size-5, $heading-spacing-5);

        @include mq($until: 1023px) {
            @include font-size(16, $heading-spacing-5);
        }

        @include mq($until: 768px) {
            @include font-size(14, $heading-spacing-5);
        }
    }
}

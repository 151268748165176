.SideNav {
    position: relative;
}

.SideNav--faq {

    @include mq($from: 920px) {
        padding-top: 8.9rem;
    }
}

.SideNav-item {
    position: relative;
    margin-top: -1px;

    &:last-child .SideNav-link {
        border-bottom: 1px solid $color-gray-100;
    }
}

.SideNav-link {
    position: relative;
    padding-top: 1.35rem;
    padding-right: 2rem;
    padding-bottom: 1.4rem;
    padding-left: 2rem;
    border-top: 1px solid $color-gray-100;
    color: $text-color;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
    transition: color 200ms, background-color 200ms, border-color 200ms;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        transition: background-color 200ms;
    }

    &::before {
        z-index: 2;
        border-radius: 1.5rem;
        background-color: transparent;
    }

    &:hover,
    &:focus,
    &:active {
        border-color: transparent !important;
        color: $color-orange-primary;

        &::before {
            background-color: $color-orange-50;
        }

        &::after {
            background-color: white;
        }
    }

    &.is-active {
        border-color: transparent !important;
        color: white;

        &::before {
            background-color: $color-orange-primary;
        }

        &::after {
            background-color: white;
        }
    }
}

.SideNav-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
}

.RelatedSlider-sliderButton {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    padding: 0;
    width: 4rem;
    height: 4rem;
    border: 1px solid rgba($color-orange-primary, 0.3);
    border-radius: 100%;
    color: $color-orange-primary;
    background-color: transparent;

    .Icon {
        width: 1.5em;
        height: 1.5em;
    }

    &:focus,
    &:hover,
    &:active {
        color: white;
        background-color: $color-orange-primary;
    }
}

.RelatedSlider-pagination {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    .Slider-pagination-item {
        margin-right: 0.4rem;
        margin-left: 0.4rem;
        background-color: $color-orange-primary;
    }

    @include mq($from: 768px) {
        display: none;
    }
}

.HeaderCategories {
    color: $color-orange-primary;
    font-weight: 400;
    font-size: 1.4rem;

    @include mq($from: 1360px) {
        font-size: 1.6rem;
    }
}

.HeaderCategories-list {
    flex-wrap: nowrap;
}

.HeaderCategories-item {
    flex: 1 1 0;

    &:hover,
    &.is-open {

        .HeaderCategories-link {
            color: white;
            text-decoration: none;
            background-color: var(--product-category-color, #{$color-orange-primary});
        }
    }

    &.is-open {

        .HeaderSubcategories {
            display: block;
        }
    }
}

.HeaderCategories-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    border-top: 3px solid var(--product-category-color, #{$color-orange-primary});
    color: var(--product-category-color, #{$color-orange-primary});
    text-decoration: none;

    &.without-icon {
        justify-content: flex-end;
        padding-top: 1rem;
        height: 100%;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: white;
        text-decoration: none;
        background-color: var(--product-category-color, #{$color-orange-primary});
    }

    @include mq($from: 1240px) {
        padding-right: 1.5rem;
        padding-bottom: 2rem;
        padding-left: 1.5rem;

        &.without-icon {
            padding-top: 2rem;
        }
    }
}

.HeaderCategories-icon {
    opacity: 0.45;
    fill: currentColor;
    width: 5rem;
    height: 5rem;

    @include mq($from: 1024px) {
        width: 6rem;
        height: 6rem;
    }
}

.HeaderCategories-label {
    display: none;
    margin-top: -0.5rem;
    letter-spacing: 0.05em;

    @include mq($from: 1240px) {
        display: block;
    }
}

.Toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: -5rem;
    margin-left: -2rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $color-orange-50;

    @include mq($from: 920px) {
        margin-top: -2rem;
        padding-top: 3rem;

        .CustomSelect {
            width: 24rem;
        }
    }
}

.Toolbar-item {
    padding-top: 2rem;
    padding-left: 2rem;
}

.Toolbar-item--right {
    margin-left: auto;

    @include mq($until: 919px) {
        width: 100%;
    }
}

.Toolbar-item--fill {
    flex-grow: 1;
}

.Toolbar-item--full {
    width: 100%;
}

.Toolbar-item--group {
    display: flex;
    align-items: center;
}

.Toolbar-label {
    flex-shrink: 0;
    position: relative;
    margin-right: 1rem;
    color: $color-orange-primary;
    font-weight: 400;
    font-size: 1.4rem;
}

.cb-Brands {
    position: relative;
    overflow: hidden;
}

.cb-Brands-items {
    display: grid;
    gap: 2rem;

    @include mq($from: 480px) {
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @include mq($from: 768px) {
        gap: 2rem;
        grid-template-columns: repeat(5, 1fr);
    }

    @include mq($from: 1240px) {
        gap: 2rem;
        grid-template-columns: repeat(7, 1fr);
    }
}

.cb-Brands-slider {
    opacity: 1;
    position: relative;
    margin: -1rem;
    padding: 1rem;
    overflow: hidden;
    transition: opacity 1000ms;

    &:not(.Slider--initialized) {
        opacity: 0;
        overflow: hidden;
    }

    .Slider-item {
        height: auto;
    }
}

.cb-Brands-sliderWrapper {
    position: relative;
}

.cb-Brands-sliderButtons {
    display: none;
    position: absolute;
    top: -8.5rem;
    right: 0;

    > * + * {
        margin-left: 0.5rem;
    }

    @include mq($from: 768px) {
        display: flex;
    }

    @include mq($from: 1550px) {
        position: static;
        top: auto;
        right: auto;

        .RelatedSlider-sliderButton--prev {
            position: absolute;
            top: 50%;
            left: -7rem;
            margin-top: 0.5rem;
            transform: translateY(-50%);
        }

        .RelatedSlider-sliderButton--next {
            position: absolute;
            top: 50%;
            right: -7rem;
            margin-top: 0.5rem;
            transform: translateY(-50%);
        }
    }
}

.cb-Brands[data-slider-items='1']
.cb-Brands[data-slider-items='2'] {

    .cb-Brands-sliderButtons {
        display: none !important;
    }
}

.cb-Brands[data-slider-items='3'] {

    @include mq($from: 480px) {

        .cb-Brands-sliderButtons {
            display: none !important;
        }
    }
}

.cb-Brands[data-slider-items='4'],
.cb-Brands[data-slider-items='5'] {

    @include mq($from: 768px) {

        .cb-Brands-sliderButtons {
            display: none !important;
        }
    }
}

.cb-Brands[data-slider-items='6'],
.cb-Brands[data-slider-items='7'] {

    @include mq($from: 1240px) {

        .cb-Brands-sliderButtons {
            display: none !important;
        }
    }
}

.Intro {
    position: relative;
    z-index: 3;
    margin-top: 0.5rem;
    margin-bottom: 3rem;

    @include mq($from: 768px) {
        margin-top: 1.5rem;
    }

    @include mq($from: 1024px) {
        margin-top: 3rem;
    }
}

.Intro--center {
    text-align: center;

    .Intro-subtitle,
    .Intro-body {
        margin-right: auto;
        margin-left: auto;
    }
}

.Intro--bottomSpacing {

    @include mq($from: 1024px) {
        margin-bottom: 5rem;
    }
}

.with-spacing {

    .Intro {
        margin-top: 0;
    }
}

.Intro-body {
    max-width: 80rem;
}

.Intro-subtitle {
    display: inline-flex;
    position: relative;
    padding-left: 4.5rem;
    color: $color-blue-primary;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    &::before {
        content: '';
        position: absolute;
        top: 1.125rem;
        left: 0;
        width: 3.5rem;
        border-top: 0.15rem solid currentColor;
    }
}

.Intro-title {
    color: $color-orange-primary;
}

.Intro-text {
    font-weight: 400;
    font-size: 1.6rem;
}

.Intro-layout.with-cta {

    @include mq($from: 580px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.Intro-cta {
    padding-top: 2rem;

    @include mq($from: 640px) {
        padding-top: 3rem;
    }
}

.Intro--customColors {

    .Intro-subtitle {
        color: var(--intro-subtitle-color, #{$color-blue-primary});
    }

    .Intro-title {
        color: var(--intro-title-color, #{$color-orange-primary});
    }
}

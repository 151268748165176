.FooterColumn-title {
    --heading-color: #{$color-blue-primary};
}

.FooterColumn-columns {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
}

.FooterColumn-link {
    display: inline-flex;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: inherit;
        text-decoration: underline;
    }
}

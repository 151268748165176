.CategoryFilters {
    position: relative;

    > * + * {
        margin-top: 3rem;
    }
}

.CategoryFilter {
    position: relative;

    .Form-field--radio .Form-indicator,
    .fui-radio .Form-indicator,
    .Form-field--checkbox .Form-indicator,
    .fui-checkbox .Form-indicator {
        border-color: $color-gray-200;
        background-color: transparent;
    }
}

.CategoryFilter-header {

    &.with-toggle {
        cursor: pointer;
    }

    &.is-active {
        padding-bottom: 1.5rem !important;
    }
}

.CategoryFilter-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: $color-orange-primary;
    font-size: 1.7rem;
}

.CategoryFilter-toggle {
    flex-shrink: 0;
    position: relative;
    top: 2px;

    .Icon {
        margin-left: 0.5rem;
    }

    &.is-active {
        transform: rotate(-180deg);

        .Icon {
            margin-right: 0.5rem;
            margin-left: 0;
        }
    }
}

.CategoryFilter-body {
    padding-bottom: 1.2rem;
}

.CategoryFilter-inner {
    position: relative;
    padding-top: 2.2rem;
    border-top: 1px solid rgba($color-orange-primary, 0.25);

    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 4rem;
        border-top: 1px solid $color-orange-primary;
    }
}

.CategoryFilter-items {
    position: relative;
    padding-left: 0;
    list-style: none;

    > * + * {
        margin-top: 0.75rem;
    }
}

.CategoryFilter-item {
    margin-bottom: 0;
}

.CategoryFilter--box {
    border-radius: 1.5rem;
    background-color: $color-gray-50;

    .CategoryFilter-header {
        padding-top: 2.2rem;
        padding-right: 2.5rem;
        padding-bottom: 2.2rem;
        padding-left: 2.5rem;
    }

    .CategoryFilter-body {
        padding-right: 2.5rem;
        padding-bottom: 2.2rem;
        padding-left: 2.5rem;
    }
}

.CategoryFilter-link {
    color: $text-color;
    text-decoration: none;
    transition: color 200ms;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: $color-orange-primary;
        text-decoration: none;
    }
}

/* DigitalPulse */
.DigitalPulse {
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    height: 1.5em;
    color: inherit;

    &-icon,
    &-body {
        display: block;
        fill: currentColor;
    }

    // Label
    &-label {
        margin-right: .3em;
        flex-shrink: 0;
    }

    // Object
    &-object {
        background-color: $color-orange-50;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: inherit;
    }

    // Icon
    &-icon {
        flex-shrink: 0;
        height: 1.2em * $digitalpulse-icon-ratio;
        transform: translateY(5%);
        width: 1.2em;
    }

    &:hover {
        color: inherit;
    }

    @include mq($viewport--lg) {

        &-object {
            // @include transition(#{transform, right}, 300ms, ease-out);
            min-width: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff * $digitalpulse-text-ratio;
            position: relative;
            z-index: 1;
            transition: right .3s;

            position: absolute;
            top: 50%;
            right: 0;
            transform: scale(1.01) translateY(-50%); // Scale Firefox fix
        }

        &:hover .DigitalPulse-object {
            right: 100%;
        }

        &-body {
            @include transition(width, 300ms, ease-out);
            margin-left: .3em;
            transform: translateY(12.5%);

            display: flex;
            justify-content: flex-end;
            height: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff;
            width: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff * $digitalpulse-text-ratio;
        }
    }

    @include mq($from: 920px) {
        margin-right: 0;
        margin-left: auto;
    }
}

@include mq($until: 1023px) {

    .DigitalPulse-body {
        display: none;
    }
}

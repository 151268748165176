/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-weight: 300;
    font-family: $font-sans;
    height: 100%;

    &.fonts-loaded {
        font-family: $font-grizo;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    height: 100%;
    overflow-x: hidden;
}

[x-cloak] {
    display: none !important;
}

.u-lg-filter-hidden {

    @include mq($from: 920px) {
        display: none !important;
    }
}

.u-md-filter-hidden {

    @include mq($until: 919px) {
        display: none !important;
    }
}

.mobile-noResults {

    @include mq($from: 920px) {
        display: none;
    }
}

strong {
    font-weight: 600;
}

.u-color-orange {
    color: $color-orange-primary;
}

.u-color-soft {
    color: rgba($text-color, 0.9);
}

.u-text-xs {
    font-size: 1.2rem;
}

.u-text-sm {
    font-size: 1.4rem;
}

.u-text-lg {
    font-size: 1.6rem;
}

.u-font-500 {
    font-weight: 500;
}

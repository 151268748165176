.SimpleAuthor {
    display: flex;
    align-items: center;

    @include mq($from: 580px) {
        margin-right: 4rem;
    }
}

.SimpleAuthor-image {
    flex-shrink: 0;
    margin-right: 1rem;
    width: 5rem;
    height: 5rem;
    overflow: hidden;
    border-radius: 100%;

    @include mq($from: 580px) {
        margin-right: 2rem;
        width: 6.5rem;
        height: 6.5rem;
    }
}

.SimpleAuthor-label {
    display: block;
    color: $color-orange-primary;
    font-weight: 600;
    text-transform: uppercase;
}

.SimpleAuthor-name {

}

.SimpleAuthorShare {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 1px solid rgba($color-orange-primary, 0.3);

    @include mq($until: 579px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > * + * {
            margin-top: 2rem;
        }
    }
}

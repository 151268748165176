.Media-object,
.ImageWrapper {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
}

.Media-object.has-ratio,
.ImageWrapper.with-ratio {
    padding-bottom: var(--image-ratio);

    > img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.Media-object.has-ratio {
    padding-bottom: var(--ratio);
}

.ImageWrapper.with-ratio {
    padding-bottom: var(--image-ratio);
}

.PricingFilter {

    .noUi-target {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        background-color: rgba($color-orange-primary, 0.25);
    }

    .noUi-horizontal {
        height: 2px;
    }

    .noUi-connects {
        border-radius: 0;
    }

    .noUi-connect {
        background-color: $color-orange-primary;
    }

    .noUi-handle {
        top: -4px;
        width: 1rem;
        height: 1rem;
        border: 0;
        border-radius: 100%;
        background-color: $color-orange-primary;
        box-shadow: none;

        &.noUi-handle-lower {
            right: -9px;
        }

        &.noUi-handle-upper {
            right: 0;
        }

        &::before,
        &::after {
            content: none;
        }
    }
}

.PricingFilter-bar {
    margin-top: -1.5rem;
    margin-right: -2.5rem;
    margin-bottom: -0.5rem;
    margin-left: -2.5rem;
    padding-top: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 2.5rem;
}

.PricingFilter-values {
    display: flex;
    align-items: center;
    color: rgba($text-color, 0.85);
    font-size: 1.2rem;

    > * + * {

        &::before {
            content: '-';
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }
}

.PricingFilter-value {
    position: relative;

    span + span {
        margin-left: 0.5rem;
    }
}

.cb-CallToActions {}

.cb-CallToActions-items--1 {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .cb-CallToActions-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq($until: 768px) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }

    .cb-CallToActions-actions {
        display: flex;
        align-items: center;

        @include mq($until: 768px) {
            margin-top: 2rem;
        }
    }

    .cb-CallToActions-action {
        margin-left: 1rem;

        @include mq($until: 768px) {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }

    @include mq($from: 640px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

.cb-CallToActions-items--2 {

    @include mq($from: 640px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .cb-CallToActions-item {
        position: relative;
        padding-top: 3rem;
        padding-bottom: 3rem;
        text-align: center;

        @include mq($from: 640px) {
            padding-top: 5rem;
            padding-bottom: 5rem;

            &:first-child:not(:last-child) {
                padding-right: 2rem;
            }

            &:last-child:not(:first-child) {
                padding-left: 2rem;
            }

            &:first-child:last-child {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }
    }

    .cb-CallToActions-actions {
        margin-top: 1rem;
    }

    .cb-CallToActions-item + .cb-CallToActions-item::before {
        content: '';
        position: absolute;
        top: 0;

        @include mq($from: 640px) {
            left: -1px;
            height: 100%;
            border-left: 2px solid white;
        }

        @include mq($until: 639px) {
            right: -2rem;
            left: -2rem;
            border-top: 2px solid white;
        }
    }
}

.cb-CallToActions-title {
    font-size: 1.6rem;
}

.ProductCategoryOverview {
    @include mq($from: 1024px) {

        .Intro {
            margin-bottom: 5rem;
        }
    }
}

.ProductCategoryOverview-items {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;

    @include mq($from: 1024px) {
        gap: 5rem;
        grid-template-columns: repeat(4, 1fr);
    }
}

.ProductCategoryOverview-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $color-gray-900;
    font-weight: 500;
    font-size: 1.6rem;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: $color-orange-primary;

        .ProductCategoryOverview-mediaIconWrapper {
            opacity: 1;
        }
    }

    @include mq($from: 1024px) {
        font-size: 1.8rem
    }
}

.ProductCategoryOverview-imageWrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    border-radius: 2.5rem;
    background-color: white;
    box-shadow: 0 4px 20px 10px rgba(black, 0.03);

    .Media-object,
    .ImageWrapper {
        transition: opacity 200ms;

        > img {
            box-sizing: border-box;
            padding: 3rem;
        }
    }

    &.without-image {

        &::before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }
}

.ProductCategoryOverview-label {
    padding-top: 2rem;
    text-align: center;
}

.ProductCategoryOverview-mediaIconWrapper {
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba($color-orange-primary, 0.75);
    transition: opacity 200ms;
}

.ProductCategoryOverview-mediaIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    color: $color-orange-primary;
    background-color: white;
}

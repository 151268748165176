/* Objects: Structure */

// Namespace
$structure-namespace:                   $namespace !default;

// Widths & Heights
$structure-gutter--l:                    40 !default;
$structure-gutter--r:                    $structure-gutter--l !default;

$structure-header-height:                auto !default;
$structure-header-gutter:                0 !default;

$structure-footer-height:                auto !default;
$structure-footer-gutter:                0 !default;

// Responsive
$structure-responsive-gutter--l:         20 !default;
$structure-responsive-gutter--r:         $structure-responsive-gutter--l !default;

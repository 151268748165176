.with-spacing {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include mq($from: 1024px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        padding-top: 11rem;
        padding-bottom: 11rem;
    }
}

.with-bg {
    color: var(--cb-color, #{$text-color});
    background-color: var(--cb-bg-color, transparent);
}

.with-bg--light {
    --heading-color: #{$color-orange-primary};
}

.with-bg--dark {
    --cb-color: white;
    --heading-color: white;
}

.with-bg--white {
    --cb-bg-color: white;
}

.with-bg--orange.with-bg--light {
    --cb-bg-color: #{$color-orange-50};
}

.with-bg--orange.with-bg--dark {
    --cb-bg-color: #{$color-orange-primary};
}

.with-bg--blue.with-bg--light {
    --cb-bg-color: #{$color-blue-50};
}

.with-bg--blue.with-bg--dark {
    --cb-bg-color: #{$color-blue-primary};
}

.with-bg--white + .with-bg--white,
.with-bg--orange.with-bg--light + .with-bg--orange.with-bg--light,
.with-bg--orange.with-bg--dark + .with-bg--orange.with-bg--dark {
    padding-top: 0;
}

.Intro + .ContentBuilder {

    .with-bg--white:first-child {
        padding-top: 0;

        @include mq($from: 768px) {
            padding-top: 2rem;
        }
    }
}

.SimpleContent + .with-spacing {
    padding-top: 0;
}

.with-symbols {
    background-image:
        url('#{$path-img}symbol-circle.svg'),
        url('#{$path-img}symbol-bone.svg'),
        url('#{$path-img}symbol-paw.svg'),
        url('#{$path-img}symbol-circle-bone.svg'),
        url('#{$path-img}symbol-circles.svg'),
        url('#{$path-img}symbol-paw.svg');
    background-repeat:
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat;
    background-position:
        1% 7%,
        1% 45%,
        1% 80%,
        50% 99%,
        99% 2%,
        99% 70%;
    background-size:
        2rem 2rem,
        4rem 4rem,
        6rem 6rem,
        7rem 7rem,
        8rem 15rem,
        4rem 4rem;

    @include mq($from: 1360px) {

        background-position:
            3% 10%,
            5% 45%,
            3% 80%,
            75% 95%,
            97% 20%,
            97% 70%;
    }

    @include mq($from: 2000px) {

        background-position:
            13% 10%,
            15% 45%,
            13% 80%,
            65% 95%,
            87% 20%,
            87% 70%;
    }

    @include mq($from: 2800px) {

        background-position:
            23% 10%,
            25% 45%,
            23% 80%,
            55% 95%,
            77% 20%,
            77% 70%;
    }
}

.with-symbols-small {
    background-image:
        url('#{$path-img}symbol-circle-light.svg'),
        url('#{$path-img}symbol-bone-light.svg'),
        url('#{$path-img}symbol-paw-light.svg');
    background-repeat:
        no-repeat,
        no-repeat,
        no-repeat;
    background-position:
        93% 80%,
        95% 20%,
        8% 30%;
    background-size:
        1.5rem 1.5rem,
        4rem 5rem,
        6rem 6rem;

    @include mq($from: 1360px) {

        background-position:
            93% 80%,
            95% 20%,
            8% 30%;
    }

    @include mq($from: 2000px) {

        background-position:
            83% 80%,
            85% 20%,
            13% 30%;
    }

    @include mq($from: 2800px) {

        background-position:
            73% 80%,
            75% 20%,
            18% 30%;
    }
}

.Footer {
    background-color: $color-orange-50;
}

.Footer-top {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include mq($from: 560px) {
        padding-top: 9rem;
    }

    @include mq($from: 1240px) {
        padding-top: 14rem;
        padding-bottom: 8rem;
    }
}

.Footer-bottom {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-top: 1px solid rgba($color-orange-primary, 0.1);
    color: rgba($text-color, 0.75);
    font-size: 1.3rem;

    > .Container {
        display: flex;
        justify-content: space-between;
    }

    @include mq($until: 919px) {
        padding-top: 2rem;
        padding-bottom: 2rem;

        > .Container {
            flex-direction: column;
            justify-content: center;
        }

        > .Container > * + * {
            margin-top: 2rem;
        }
    }
}

.Footer-logo {
    max-width: 22.5rem;
}

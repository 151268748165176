.lf-Images {
    margin-right: -2rem;
    margin-left: -2rem;
}

.lf-Images-items--2 {
    display: grid;
    gap: 2rem;

    @include mq($from: 580px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

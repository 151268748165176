.BrandsBoxes {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: 480px) {
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @include mq($from: 768px) {
        gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
    }

    @include mq($from: 1240px) {
        gap: 2rem;
        grid-template-columns: repeat(5, 1fr);
    }
}

.BrandsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding-right: 2rem;
    padding-left: 2rem;
    height: 100%;
    border-radius: 1.5rem;
    overflow: hidden;
    color: $text-color;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    background-color: white;
    box-shadow:
        0px 0px 5.3px rgba(0, 0, 0, 0.008),
        0px 0px 17.9px rgba(0, 0, 0, 0.012),
        0px 0px 15px rgba(0, 0, 0, 0.02)
    ;


    &:hover,
    &:focus,
    &:active {
        color: $text-color;
        text-decoration: none;

        .BrandsBox-media img {
            opacity: 0.25;
        }

        .BrandsBox-mediaIconWrapper {
            opacity: 1;
        }
    }
}

.BrandsBox-body {
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 100%;
}

.BrandsBox-mediaIconWrapper {
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba($color-orange-primary, 0.75);
    transition: opacity 200ms;
}

.BrandsBox-mediaIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    color: $color-orange-primary;
    background-color: white;
}

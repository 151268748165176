/* Colors: Theme */

// Lay-out
$primary-color:                     currentColor !default;
$secondary-color:                   currentColor !default;
$tertiary-color:                    currentColor !default;

$text-color:                        $color-gray-500 !default;
$bg-color:                          $white !default;
$border-color:                      $color-gray-200 !default;
$image-color:                       $color-gray-200 !default;

$header-bg-color:                   white !default;
$footer-bg-color:                   white !default;

// Modifiers
$theme-modifiers: (
    "primary": $cyan,
    "secondary": $grey--600,
    "success": $green,
    "info": $blue,
    "warning": $orange,
    "danger": $red,
    "error": $red,
    "light": $grey--100,
    "dark": $grey--800
) !default;

.FooterTopGrid {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: 768px) {
        column-gap: 10rem;
    }

    @include mq($from: 1240px) {
        gap: 6rem;
        grid-template-columns: 1.25fr 1.25fr 0.5fr 0.5fr 1fr;
    }
}

.FooterTopGrid-col--info {

    .FooterTopGrid-inner {
        max-width: 27rem;
    }

    @include mq($until: 559px) {
        grid-column: span 2 / span 2;
    }

    @include mq($from: 560px) {
        margin-top: -5rem;
    }
}

.FooterTopGrid-col--categories,
.FooterTopGrid-col--newsletter {

    @include mq($until: 559px) {
        grid-column: span 2 / span 2;
    }
}

.Pagination + .PaginationProgress {
    padding-top: 3rem;
}

.PaginationProgress {
    margin-right: auto;
    margin-left: auto;
    padding-top: 4rem;
    max-width: 27rem;
    text-align: center;

    @include mq($from: 1024px) {
        padding-top: 6rem;
    }

    @include mq($from: 1240px) {
        padding-top: 8rem;
    }
}

.PaginationProgress-text {}

.PaginationProgress-bar {
    appearance: none;
    margin-top: 1rem;
    width: 100%;
    height: 2px;
    border: 0;
    color: $color-orange-primary;
    background-color: rgba($color-orange-primary, 0.15);
}

/* For Firefox: progress bar */
.PaginationProgress-bar::-moz-progress-bar {
    background-color: rgba($color-orange-primary, 0.15);
}

/* WebKit/Blink browsers: -webkit-progress-bar is to style the container */
.PaginationProgress-bar::-webkit-progress-bar {
    background-color: rgba($color-orange-primary, 0.15);
}

/* -webkit-progress-value is to style the progress bar. */
.PaginationProgress-bar::-webkit-progress-value {
    background-color: rgba($color-orange-primary, 1);
}

.PaginationProgress-button {
    margin-top: 1.5rem;
}

.Quantity {
    display: inline-flex;
    align-items: center;
}

.Quantity-amount {
    pointer-events: none;
    position: relative;
    top: -1px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    min-width: 3rem;
    color: $color-orange-primary;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1;
    text-align: center;
}

.Quantity-amount--field {
    pointer-events: inherit;
    margin-right: 1rem;
    margin-left: 1rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 5rem;
    min-width: 0;
    font-weight: 400;
    color: $text-color;
    text-align: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}

.Quantity-button {
    @extend %button !optional;
    padding: 0;
    width: 2.7rem;
    height: 2.7rem;
    border: 1px solid $color-orange-100;
    border-radius: 100%;
    color: $color-orange-primary;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
        border-color: $color-orange-primary;
        color: white;
        background-color: $color-orange-primary;
    }
}

.Quantity-button--fill {
    width: 2.2rem;
    height: 2.2rem;
    border-color: $color-orange-primary;
    color: white;
    background-color: $color-orange-primary;

    &:hover,
    &:focus,
    &:active {
        border-color: $color-orange-600;
        color: white;
        background-color: $color-orange-600;
    }
}

.lf-Quote {
    padding-top: 1rem;
}

.lf-Quote-inner {
    position: relative;
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    padding-left: 3rem;
    border-left: 0.5rem solid $color-orange-primary;
    color: $color-orange-primary;
    font-weight: 500;
    font-size: 1.5rem;
    background-color: rgba($color-orange-primary, 0.075);

    .Icon {
        position: absolute;
        top: -1.75rem;
        left: 3rem;
        width: 4rem;
        height: 3rem;
    }

    @include mq($from: 768px) {
        padding-top: 3rem;
        padding-right: 4rem;
        padding-bottom: 3rem;
        padding-left: 4rem;
        border-left-width: 1rem;
        font-size: 1.8rem;

        .Icon {
            left: 4rem;
        }
    }

    @include mq($from: 1024px) {
        padding-top: 4rem;
        padding-right: 9rem;
        padding-bottom: 4rem;
        padding-left: 5rem;
        font-size: 1.8rem;

        .Icon {
            left: 5rem;
        }
    }
}

.cb-Blocks {

    &:first-child {
        padding-top: 2rem;
    }
}

.cb-Blocks-grid {
    display: grid;
    gap: 2rem;

    &[data-blocks-items='1'] {

        @include mq($from: 768px) {

            [data-block-id='1'] {
                grid-column: span 6 / span 6;
            }
        }
    }

    &[data-blocks-items='2'] {

        @include mq($from: 768px) {

            [data-block-id='1'],
            [data-block-id='2'] {
                grid-column: span 3 / span 3;
            }
        }
    }

    &[data-blocks-items='4'] {

        @include mq($from: 768px) {

            [data-block-id='4'] {
                grid-column: span 6 / span 6;
            }
        }
    }

    &[data-blocks-items='5'] {

        @include mq($from: 768px) {

            [data-block-id='4'],
            [data-block-id='5'] {
                grid-column: span 3 / span 3;
            }
        }
    }

    &[data-blocks-items='3'],
    &[data-blocks-items='4'],
    &[data-blocks-items='5'] {

        [data-block-id='1'] {

            .cb-Block-bodyInner {
                max-width: 47rem;
            }

            @include mq($from: 768px) {
                grid-row: span 2 / span 2;
                grid-column: span 6 / span 6;
                min-height: 45rem;
            }

            @include mq($from: 1024px) {
                grid-column: span 4 / span 4;
                min-height: 65rem;
            }
        }

        [data-block-id='2'],
        [data-block-id='3'] {

            .cb-Block.with-smallSizeImage {

                .cb-Block-image {

                    .Media-object,
                    .ImageWrapper {
                        border-radius: 0;
                    }
                }

                .cb-Block-bodyInner {
                    margin-top: 0;
                }
            }

            @include mq($from: 480px) {

                .cb-Block.with-smallSizeImage {
                    grid-template-columns: repeat(2, 1fr);

                    &.without-category {
                        padding-right: 0;
                    }

                    .Media-object,
                    .ImageWrapper {

                        > img {
                            object-position: center right;
                        }
                    }
                }
            }

            @include mq($until: 767px) {

                .cb-Block.with-smallSizeImage {

                    .cb-Block-image {

                        .Media-object,
                        .ImageWrapper {
                            padding-bottom: var(--image-ratio);
                        }
                    }
                }
            }

            @include mq($from: 768px) {
                grid-column: span 3 / span 3;
            }

            @include mq($from: 1024px) {
                grid-column: span 2 / span 2;
            }
        }

        [data-block-id='4'],
        [data-block-id='5'] {

            .cb-Block-bodyInner {
                max-width: 40rem;
            }
        }
    }

    @include mq($from: 768px) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.cb-Blocks-column {}

.cb-Block {
    --btn-text-color: white;
    --btn-border-color: var(--cb-block-secondary-color, #{$color-orange-primary});
    --btn-bg-color: var(--cb-block-secondary-color, #{$color-purple-primary});
    --btn-border-color-hover: var(--cb-block-secondary-color, #{$color-orange-600});
    --btn-text-color-hover: white;
    --btn-bg-color-hover: var(--cb-block-secondary-color, #{$color-orange-600});
    --btn-border-color-active: var(--cb-block-secondary-color, #{$color-orange-600});
    --btn-text-color-active: white;
    --btn-bg-color-active: var(--cb-block-secondary-color, #{$color-orange-600});
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 3.5rem;
    padding-right: 3.5rem;
    padding-bottom: 3.5rem;
    padding-left: 3.5rem;
    min-height: 25rem;
    height: 100%;
    border-radius: 4.5rem;
    overflow: hidden;
    color: var(--cb-block-text-color, #{$color-gray-900});
    text-decoration: none;
    background-color: var(--cb-block-bg-color, #{$color-orange-50});
    transition: box-shadow 200ms;

    &.is-link {

        &:hover,
        &:focus,
        &:active {
            color: var(--cb-block-text-color-hover, #{$color-gray-900});
            text-decoration: none;
            box-shadow: 0 4px 20px 10px rgba(black, 0.06);

            .cb-Block-image.is-fullSize {
                opacity: 0.85;
            }
        }
    }

    &.with-category,
    &.without-category.without-default {
        --cb-block-title-color: white;
        --cb-block-text-color: white;
        --cb-block-text-color-hover: white;
    }

    &.with-smallSizeImage {
        display: grid;
        gap: 2rem;
        align-items: center;

        .cb-Block-image {

            .Media-object,
            .ImageWrapper {
                padding-bottom: 100%;
                border-radius: 1.5rem;

                > img {
                    object-fit: contain;
                }
            }
        }

        @include mq($from: 480px) {
            grid-template-columns: 2fr 1fr;

            .cb-Block-image {
                order: 99;
            }
        }

        @include mq($from: 1024px) {
            grid-template-columns: 1fr minmax(auto, 18rem);
        }
    }
}

.cb-Block-subtitle {
    display: block;
    color: var(--cb-block-secondary-color, #{$color-orange-primary});
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.cb-Block-title {
    color: var(--cb-block-title-color, #{$color-blue-primary});
}

.cb-Block-body {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.cb-Block-bodyInner {
    margin-top: auto;
    max-width: 31rem;
}

.cb-Block.with-category {

    .cb-Block-body {
        height: 100%;
    }
}

.cb-Block.with-fullSizeImage {
    justify-content: center;

    .cb-Block-bodyInner {
        margin-top: 0;
        max-width: 24rem;
    }
}

.cb-Block-image {
    transition: opacity 200ms;

    &.is-fullSize {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .Media-object,
        .ImageWrapper {
            --image-ratio: 0 !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.cb-Block-icon {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 8rem;
    height: 8rem;
    fill: rgba(white, 0.2);
}

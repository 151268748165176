.SiteOverlayLanguages {
    overflow: hidden;
}

.SiteOverlayLanguages-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.3rem;
    width: 100%;
    color: $text-color;
    transition: color 200ms;

    &:hover,
    &:focus,
    &:active {
        color: $color-orange-primary;
    }
}

.SiteOverlayLanguages-icon {
    color: $color-orange-primary;
    transition: color 200ms;

    .Icon {
        width: 2rem;
        height: 2rem;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color-orange-600;
    }
}

.SiteOverlayLanguages-listWrapper {
    position: absolute;
    top: 1px;
    right: -3rem;
    left: -3rem;
    z-index: 1;
    padding-top: 1.5rem;
    padding-right: 3rem;
    padding-bottom: 3.5rem;
    padding-left: 3rem;
    background-color: $color-orange-50;
}

.SiteOverlayLanguages-item {
    position: relative;
}

.SiteOverlayLanguages-item + .SiteOverlayLanguages-item {
    margin-top: 1.5rem;
    padding-top: 1.5rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -2rem;
        left: -2rem;
        border-top: 1px solid rgba($color-orange-primary, 0.1);
    }

    @include mq($from: 375px) {

        &::before {
            right: -3rem;
            left: -3rem;
        }
    }
}

.SiteOverlayLanguages-link {
    color: $text-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: $text-color;
        text-decoration: underline;
    }
}

.SiteOverlayLanguages-choose {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
}

.SiteOverlayLanguages--enter {
    transition: transform 500ms;
    transition-delay: 0;
}

.SiteOverlayLanguages--leave {
    transition: transform 500ms;
    transition-delay: 0;
}

.SiteOverlayLanguages--enter-start,
.SiteOverlayLanguages--leave-end {
    transform: translateX(100%);
}

.SiteOverlayLanguages--enter-end,
.SiteOverlayLanguages--leave-start {
    transform: translateX(0);
}

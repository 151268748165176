.AccountLogin {
    padding-bottom: 4rem;

    @include mq($from: 768px) {
        padding-top: 2rem;
    }

    @include mq($from: 1024px) {
        padding-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        padding-bottom: 11rem;
    }
}

.AccountLogin-layout--grid {
    display: grid;
    gap: 2rem;

    @include mq($from: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.AccountLogin-item {
    --heading-color: #{$color-orange-primary};
    padding-top: 3rem;
    padding-right: 4rem;
    padding-bottom: 3rem;
    padding-left: 4rem;
    border-radius: 1.5rem;
    background-color: $color-orange-50;

    .Form-item--action {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &.with-extraLink {

            .Form-extraLink {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }

            .Link {
                font-size: 1.3rem;
            }
        }
    }

    @include mq($from: 1024px) {
        padding-top: 5rem;
        padding-right: 8rem;
        padding-bottom: 5rem;
        padding-left: 8rem;
    }
}

.AccountLogin-inner {
    margin-right: auto;
    margin-left: auto;
    max-width: 72rem;

    form {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        max-width: 40rem;
    }
}

.AccountLogin-text {

    p {
        font-weight: 400;
        font-size: 1.575rem;
    }

    ul,
    ol {
        padding-left: 0;
        list-style: none;

        li {
            @include background-svg('<svg viewBox="0 0 20 20" fill="#E36410" xmlns="http://www.w3.org/2000/svg"><path d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM8.001 14.413L4.99545 11.4139C4.6047 11.024 4.60391 10.3912 4.99369 10.0003C5.38371 9.60918 6.01701 9.60846 6.40793 9.9987L7.999 11.587L12.586 7C12.9765 6.60953 13.6095 6.60953 14 7C14.3905 7.39047 14.3905 8.02353 14 8.414L8.001 14.413Z"/></svg>');
            margin-bottom: 0;
            padding-left: 3rem;
            background-repeat: no-repeat;
            background-size: 2rem 2rem;
            background-position: top 0.175rem left;

            + li {
                margin-top: 1.5rem;
            }
        }
    }
}

.AccountLogin-registerForm {
    max-width: 84rem;

    .Form-item--action {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: -2rem;
        margin-left: -1rem;

        > * {
            padding-top: 2rem;
            padding-left: 1rem;
        }

        > *:last-child {
            order: -1;
        }

        @include mq($from: 640px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            > *:last-child {
                order: 1;
            }
        }
    }
}

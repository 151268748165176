.Cart {
    margin-bottom: 4rem;

    @include mq($from: 1024px) {
        margin-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        margin-bottom: 11rem;
    }
}

.Cart-row {
    display: grid;
    gap: 2rem;
    grid-template-columns: 6rem 1fr 1fr 2rem;
    align-items: center;

    @include mq($from: 700px) {
        grid-template-columns: 6rem 1.5fr 1fr 11.5rem 1fr 2rem;
    }

    @include mq($from: 920px) {
        gap: 4rem;
    }
}

.Cart-row--header {
    padding-bottom: 2rem;
    border-bottom: 1px solid $color-orange-primary;
    font-weight: 500;
    font-size: 1.8rem;

    .Cart-column--product {
        grid-column: 1/3;
    }

    @include mq($until: 699px) {
        display: none;
    }
}

.Cart-row--item {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include mq($until: 699px) {

        .Cart-column--product {
            grid-column: 2/5;
        }

        .Cart-column--unitPrice {
            display: none;
        }

        .Cart-column--quantity {
            grid-column: 2/3;
            text-align: left !important;
        }

        .Cart-column--totalPrice {
            text-align: right !important;
        }
    }

    @include mq($until: 399px) {

        .Cart-column--image {
            display: none;
        }

        .Cart-column--product {
            grid-column: 1/5;
        }

        .Cart-column--quantity {
            grid-column: 1/3;
        }
    }
}

.Cart-row--item + .Cart-row--item,
.Cart-adjustments + .Cart-row--item {
    border-top: 1px solid $color-orange-200;
}

.Cart-productTitle {
    color: inherit;
    font-size: 1.6rem;
    line-height: 1.5;
}

.Cart-variantTitle {
    display: block;
    font-weight: 400;
    font-size: 1.5rem;
}

.Cart-productLink {
    color: $text-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: $color-orange-primary;
        text-decoration: none;
    }
}

.Cart-remove {
    @extend %button !optional;
    padding: 0;
    width: 2rem;
    border-radius: 0;
    color: $text-color;
    font-size: 2rem;
    line-height: 1;

    &:hover,
    &:focus,
    &:active {
        color: $color-orange-primary;
    }
}

.Cart-footer {
    margin-top: 2rem;
    margin-left: auto;
    max-width: 41rem;

    .Cart-row--total {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid $color-orange-primary;
        color: $color-orange-primary;
        font-weight: 500;
        font-size: 1.8rem;
    }
}

.Cart-row--footer {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr auto;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.Cart-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;

    @include mq($until: 519px) {

        > * + * {
            margin-bottom: 2rem;
        }
    }

    @include mq($from: 520px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.Cart-action--next {

    @include mq($until: 519px) {
        order: -1;
    }
}

.Cart-body {

    .Cart-column--image {

        .Media-object,
        .ImageWrapper {

            > img {
                object-fit: contain;
            }
        }
    }

    @include mq($until: 699px) {
        border-top: 1px solid $color-orange-primary;
        border-bottom: 1px solid $color-orange-primary;
    }
}

.ProductCart-price-old {
    margin-right: 0.5rem;
    color: $color-gray-600 !important;
    font-size: 1.2rem;
    text-decoration: line-through;
}

.Cart-adjustments {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $color-orange-50;
}

.Cart-row--adjustments {

    @include mq($until: 699px) {

        .Cart-column--totalPrice {
            grid-column-end: 4;
            text-align: right !important;
        }
    }

    @include mq($until: 399px) {
        padding-left: 2rem;

        .Cart-column--image {
            display: none;
        }
    }
}

.Cart-row--adjustments + .Cart-row--adjustments {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px dashed $color-orange-100;
}

.Cart-column--adjustments {
    grid-column: span 2 / span 2;
    margin-top: 1rem;
    padding: 1rem 2rem;
    background-color: $color-orange-50;
}

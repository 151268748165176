.cb-GoogleMaps {

    .FlexEmbed::before {
        padding-bottom: 100%;
    }

    @include mq($from: 560px) {

        .FlexEmbed::before {
            padding-bottom: 60%;
        }
    }

    @include mq($from: 768px) {

        .FlexEmbed::before {
            padding-bottom: 40%;
        }
    }
}

.cb-GoogleMaps-wrapper {
    position: relative;
    z-index: 1;
    border-radius: 1.5rem;
    overflow: hidden;
}

.cb-GoogleMaps {

    .gm-style-iw.gm-style-iw-c {
        padding: 0 !important;
        min-width: 20rem !important;
        max-height: none !important;
        color: white !important;
        background-color: $color-blue-primary !important;

        > button {
            top: 0 !important;
            right: 0 !important;

            > img {
                display: none !important;
            }

            &::before {
                content: '\00d7';
                color: white !important;
                font-size: 1.6rem;
            }
        }
    }

    .gm-style-iw-d {
        max-height: none !important;
        overflow: hidden !important;
    }

    .gm-style-iw-t::after {
        background: linear-gradient(45deg, $color-blue-primary 50%, rgba(0,0,250,0) 51%, rgba(0,0,250,0));
    }

    .MarkerInfo {
        padding-top: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        font-size: 1.5rem;
        font-family: $font-grizo;
        line-height: 1.5;

        strong {
            font-weight: 700;
        }
    }

    .MarkerInfo-intro {

        > * + * {
            margin-top: 1rem;
        }
    }
}

.Longform {

    > * + * {
        margin-top: 3rem;

        @include mq($from: 768px) {
            margin-top: 4rem;
        }
    }
}

.LayoutWrapper {
    margin-bottom: 4rem;

    @include mq($from: 1024px) {
        margin-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        margin-bottom: 11rem;
    }
}

.LayoutWrapper.with-topSpacing {
    margin-top: 0.5rem;

    @include mq($from: 768px) {
        margin-top: 1.5rem;
    }

    @include mq($from: 1024px) {
        margin-top: 3rem;
    }
}

.Layout {
    display: grid;
    position: relative;
    gap: 5rem;

    @include mq($from: 920px) {
        grid-template-columns: minmax(0, 32.5rem) minmax(0, 1fr);

        &.Layout--reverse {
           grid-template-columns: minmax(0, 1fr) minmax(0, 32.5rem);
        }
    }
}

.Layout--gap-sm {
    gap: 2rem;
}

.Layout--gap-lg {

    @include mq($from: 1024px) {
        gap: 6rem;
    }

    @include mq($from: 1360px) {
        gap: 11.5rem;
    }
}

.Layout--gap-xl {

    @include mq($from: 1024px) {
        gap: 10rem;
    }

    @include mq($from: 1360px) {
        gap: 20rem;
    }
}

.Layout--sidebar-lg {

    @include mq($from: 1240px) {
        grid-template-columns: minmax(0, 34.4rem) minmax(0, 1fr);

        &.Layout--reverse {
           grid-template-columns: minmax(0, 1fr) minmax(0, 34.4rem);
        }
    }
}

.Layout--sidebar-sm {

    @include mq($from: 920px) {
        grid-template-columns: minmax(0, 23rem) minmax(0, 1fr);

        &.Layout--reverse {
           grid-template-columns: minmax(0, 1fr) minmax(0, 23rem);
        }
    }
}

.Layout-inner {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem;
    border-radius: 1.5rem;
    background-color: $color-orange-50;

    @include mq($from: 1240px) {
        padding-top: 6.5rem;
        padding-right: 8rem;
        padding-bottom: 6.5rem;
        padding-left: 8rem;
    }
}

.Layout-sidebar--reverse {

    @include mq($from: 920px) {
        order: 1;
    }
}

.Layout-body {
    position: relative;
}

.Layout-boxes {
    display: grid;
    gap: 2rem;

    @include mq($from: 700px, $until: 920px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

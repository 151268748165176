.lf-Title {
    --heading-color: #{$color-orange-primary};

    h2, .#{$namespace}Bravo {
        @include font-size($heading-size-3, $heading-spacing-3);

        @include mq($until: 1023px) {
            @include font-size(22, $heading-spacing-3);
        }

        @include mq($until: 768px) {
            @include font-size(18, $heading-spacing-3);
        }
    }
}

.cb-Gallery {
    display: flex;
    overflow: hidden;

    .Media-object,
    .ImageWrapper {
        --ratio: 33.5rem !important;
        --image-ratio: 33.5rem !important;

        > img {
            transition: transform 200ms;
        }
    }
}

.cb-Gallery-image {
    flex: 1 1 0;
}

.cb-Gallery-link {
    cursor: pointer;
    display: block;

    &:hover,
    &:focus,
    &:active {

        .Media-object,
        .ImageWrapper {

            > img {
                transform: scale(1.1);
            }
        }
    }
}

.FooterSocialMedia {}

.FooterSocialMedia-item {
    margin-right: 0.75rem;
}

.FooterSocialMedia-link {
    color: $color-orange-primary;

    .Icon {
        width: 3.2rem;
        height: 3.2rem;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color-orange-600;
    }
}

.FooterSocialMedia--alt {

    .FooterSocialMedia-link {
        border-radius: 100%;
        color: $color-blue-primary;
        background-color: white;

        &:hover,
        &:focus,
        &:active {
            color: white;
            background-color: $color-blue-primary;
        }
    }
}

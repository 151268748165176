.FilterReset {
    @extend %button !optional;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid $color-gray-300;
    border-radius: 100px;
    color: $color-gray-300;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    &:hover,
    &:focus,
    &:active {
        color: white;
        background-color: $color-gray-300;
    }
}

/* Link */

.Link {
    font-weight: 300;
    font-size: inherit;
    color: var(--btn-text-color);
    letter-spacing: 0.05em;
    text-decoration: var(--btn-text-decoration);

    .Link-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        width: 1.5em;
        height: 1.5em;
        border-radius: 100%;
        color: var(--btn-icon-color);
        background-color: var(--btn-icon-bg-color);
        transition: color 200ms, background-color 200ms;
    }

    .Link-icon--sm {
        width: 1.25em;
        height: 1.25em;
    }

    &:hover,
    &.is-hover,
    &:focus,
    &.is-focus {
        color: var(--btn-text-color-hover);
        text-decoration: var(--btn-text-decoration-hover);

        .Link-icon {
            color: var(--btn-icon-color);
            background-color: var(--btn-icon-bg-color-hover);
        }
    }

    &:active,
    &.is-active {
        color: var(--btn-text-color-active);
        text-decoration: var(--btn-text-decoration-active);

        .Link-icon {
            color: var(--btn-icon-color);
            background-color: var(--btn-icon-bg-color-active);
        }
    }
}

.Link--primary {
    --btn-text-color: #{$color-orange-primary};
    --btn-text-decoration: none;
    --btn-icon-color: white;
    --btn-icon-bg-color: #{$color-orange-primary};
    --btn-text-color-hover: #{$color-orange-600};
    --btn-text-decoration-hover: none;
    --btn-icon-color-hover: white;
    --btn-icon-bg-color-hover: #{$color-orange-600};
    --btn-text-color-active: #{$color-orange-600};
    --btn-text-decoration-active: none;
    --btn-icon-color-active: white;
    --btn-icon-bg-color-active: #{$color-orange-600};
}

.Link--secondary {
    --btn-text-color: #{$color-blue-primary};
    --btn-text-decoration: none;
    --btn-icon-color: white;
    --btn-icon-bg-color: #{$color-blue-primary};
    --btn-text-color-hover: #{$color-blue-700};
    --btn-text-decoration-hover: none;
    --btn-icon-color-hover: white;
    --btn-icon-bg-color-hover: #{$color-blue-700};
    --btn-text-color-active: #{$color-blue-700};
    --btn-text-decoration-active: none;
    --btn-icon-color-active: white;
    --btn-icon-bg-color-active: #{$color-blue-700};
}

.Link--tertiary {
    --btn-text-color: white;
    --btn-text-decoration: none;
    --btn-icon-color: #{$color-orange-primary};
    --btn-icon-bg-color: white;
    --btn-text-color-hover: white;
    --btn-text-decoration-hover: underline;
    --btn-icon-color-hover: #{$color-orange-primary};
    --btn-icon-bg-color-hover: white;
    --btn-text-color-active: white;
    --btn-text-decoration-active: none;
    --btn-icon-color-active: #{$color-orange-primary};
    --btn-icon-bg-color-active: white;
}

.Link--default {
    --btn-text-color: #{$text-color};
    --btn-text-decoration: underline;
    --btn-icon-color: #{$text-color};
    --btn-icon-bg-color: #{$text-color};
    --btn-text-color-hover: #{$color-orange-primary};
    --btn-text-decoration-hover: underline;
    --btn-icon-color-hover: #{$color-orange-primary};
    --btn-icon-bg-color-hover: #{$color-orange-primary};
    --btn-text-color-active: #{$color-orange-primary};
    --btn-text-decoration-active: underline;
    --btn-icon-color-active: #{$color-orange-primary};
    --btn-icon-bg-color-active: #{$color-orange-primary};
}

.Link--soft {
    --btn-text-color: #{$color-orange-primary};
    --btn-text-decoration: none;
    --btn-icon-color: #{$color-orange-primary};
    --btn-icon-bg-color: #{$color-orange-100};
    --btn-text-color-hover: #{$color-orange-600};
    --btn-text-decoration-hover: none;
    --btn-icon-color-hover: #{$color-orange-primary};
    --btn-icon-bg-color-hover: #{$color-orange-200};
    --btn-text-color-active: #{$color-orange-600};
    --btn-text-decoration-active: none;
    --btn-icon-color-active: #{$color-orange-primary};
    --btn-icon-bg-color-active: #{$color-orange-200};
}

.Link.with-icon {
    display: inline-flex;
    align-items: center;
}

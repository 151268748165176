///*
/// Stack
///---

$stack-spacing: $spacing-unit;
$stack-ratio: $line-height / $font-size;

$stack-spacing-s1: $stack-spacing / $stack-ratio;
$stack-spacing-s2: $stack-spacing-s1 / $stack-ratio;
$stack-spacing-s3: $stack-spacing-s2 / $stack-ratio;
$stack-spacing-s4: $stack-spacing-s3 / $stack-ratio;
$stack-spacing-s5: $stack-spacing-s4 / $stack-ratio;
$stack-spacing-md: $stack-spacing;
$stack-spacing-l1: $stack-spacing * $stack-ratio;
$stack-spacing-l2: $stack-spacing-l1 * $stack-ratio;
$stack-spacing-l3: $stack-spacing-l2 * $stack-ratio;
$stack-spacing-l4: $stack-spacing-l3 * $stack-ratio;
$stack-spacing-l5: $stack-spacing-l4 * $stack-ratio;

.Stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    // Only Child
    &:only-child {
        height: 100%;
    }

    // Reset Spacing
    > * {
        margin-top: 0;
        margin-bottom: 0;
    }

    // Default Spacing
    > :not([hidden]) ~ :not([hidden]) {
        margin-top: var(--stack-spacing);
    }
}




///*
/// Spacing

.Stack--md > * + * {
    --stack-spacing: #{u($stack-spacing-md)};
}

.Stack--s1 > * + * {
    --stack-spacing: #{u($stack-spacing-s1)};
}

.Stack--s2 > * + * {
    --stack-spacing: #{u($stack-spacing-s2)};
}

.Stack--s3 > * + * {
    --stack-spacing: #{u($stack-spacing-s3)};
}

.Stack--s4 > * + * {
    --stack-spacing: #{u($stack-spacing-s4)};
}

.Stack--s5 > * + * {
    --stack-spacing: #{u($stack-spacing-s5)};
}

.Stack--l1 > * + * {
    --stack-spacing: #{u($stack-spacing-l1)};
}

.Stack--l2 > * + * {
    --stack-spacing: #{u($stack-spacing-l2)};
}

.Stack--l3 > * + * {
    --stack-spacing: #{u($stack-spacing-l3)};
}

.Stack--l4 > * + * {
    --stack-spacing: #{u($stack-spacing-l2)};

    @include mq($from: 1024px) {
        --stack-spacing: #{u($stack-spacing-l4)};
    }
}

.Stack--l5 > * + * {
    --stack-spacing: #{u($stack-spacing-l5)};
}

/* Button */

.Button {
    border-color: var(--btn-border-color);
    letter-spacing: 0.05em;
}

.Button {
    color: var(--btn-text-color);
    background-color: var(--btn-bg-color);

    &:hover,
    &.is-hover,
    &:focus,
    &.is-focus {
        border-color: var(--btn-border-color-hover);
        color: var(--btn-text-color-hover);
        background-color: var(--btn-bg-color-hover);
    }

    &:active,
    &.is-active {
        border-color: var(--btn-border-color-active);
        color: var(--btn-text-color-active);
        background-color: var(--btn-bg-color-active);
    }
}

.Button--primary {
    --btn-border-color: #{$color-orange-primary};
}

.Button--primary:not(.Button--outline) {
    --btn-text-color: white;
    --btn-bg-color: #{$color-orange-primary};
    --btn-border-color-hover: #{$color-orange-600};
    --btn-text-color-hover: white;
    --btn-bg-color-hover: #{$color-orange-600};
    --btn-border-color-active: #{$color-orange-600};
    --btn-text-color-active: white;
    --btn-bg-color-active: #{$color-orange-600};
}

.Button--primary.Button--outline {
    --btn-text-color: #{$color-orange-primary};
    --btn-bg-color: transparent;
    --btn-border-color-hover: #{$color-orange-primary};
    --btn-text-color-hover: white;
    --btn-bg-color-hover: #{$color-orange-primary};
    --btn-border-color-active: #{$color-orange-primary};
    --btn-text-color-active: white;
    --btn-bg-color-active: #{$color-orange-primary};
}

.Button--secondary {
    --btn-border-color: #{$color-blue-primary};
}

.Button--secondary:not(.Button--outline) {
    --btn-text-color: white;
    --btn-bg-color: #{$color-blue-primary};
    --btn-border-color-hover: #{$color-blue-700};
    --btn-text-color-hover: white;
    --btn-bg-color-hover: #{$color-blue-700};
    --btn-border-color-active: #{$color-blue-700};
    --btn-text-color-active: white;
    --btn-bg-color-active: #{$color-blue-700};
}

.Button--secondary.Button--outline {
    --btn-text-color: #{$color-blue-primary};
    --btn-bg-color: transparent;
    --btn-border-color-hover: #{$color-blue-primary};
    --btn-text-color-hover: white;
    --btn-bg-color-hover: #{$color-blue-primary};
    --btn-border-color-active: #{$color-blue-primary};
    --btn-text-color-active: white;
    --btn-bg-color-active: #{$color-blue-primary};
}

.Button--tertiary {
    --btn-border-color: white;
}

.Button--tertiary:not(.Button--outline) {
    --btn-text-color: #{$color-orange-primary};
    --btn-bg-color: white;
    --btn-border-color-hover: white;
    --btn-text-color-hover: #{$color-orange-600};
    --btn-bg-color-hover: white;
    --btn-border-color-active: white;
    --btn-text-color-active: #{$color-orange-600};
    --btn-bg-color-active: white;
}

.Button--tertiary.Button--outline {
    --btn-text-color: white;
    --btn-bg-color: transparent;
    --btn-border-color-hover: white;
    --btn-text-color-hover: #{$color-orange-primary};
    --btn-bg-color-hover: white;
    --btn-border-color-active: white;
    --btn-text-color-active: #{$color-orange-primary};
    --btn-bg-color-active: white;
}

.Button.with-icon {
    display: inline-flex;
    align-items: center;
    padding-left: 2rem;
}

.Button.with-icon-reverse {
    display: inline-flex;
    align-items: center;
    padding-right: 2rem;
}

.Button--full {
    justify-content: space-between;
    width: 100%;
}

.Button--download {
    --btn-border-color: #{$color-gray-50};
    --btn-text-color: #{$text-color};
    --btn-bg-color: #{$color-gray-50};
    --btn-border-color-hover: #{$color-blue-primary};
    --btn-text-color-hover: white;
    --btn-bg-color-hover: #{$color-blue-primary};
    --btn-border-color-active: #{$color-blue-primary};
    --btn-text-color-active: white;
    --btn-bg-color-active: #{$color-blue-primary};
    box-shadow: 0 4px 20px rgba($color-orange-primary, 0.1);
}

.cb-ContentMedia {}

.cb-ContentMedia-layout {

    &.with-grid {
        display: grid;
        gap: 3rem;
        align-items: center;

        @include mq($from: 768px) {
            gap: 4rem;

            &.is-media-right {
                grid-template-columns: 0.925fr 1.075fr;
            }

            &.is-media-left {
                grid-template-columns: 1.075fr 0.925fr;
            }
        }

        @include mq($from: 1024px) {
            gap: 7.5rem;
        }

        @include mq($from: 1240px) {
            gap: 11.5rem;
        }
    }
}

.cb-ContentMedia-media {

    .Media-object,
    .ImageWrapper,
    .VideoWrapper {
        border-radius: 2.5rem;
    }

    @include mq($from: 768px) {

        &.is-right {
            order: 2;
        }
    }
}

.cb-ContentMedia-title {
    color: var(--cb-title-color, var(--heading-color));
}

.cb-ContentMedia-actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;
    margin-left: -1rem;
}

.cb-ContentMedia-action {
    padding-top: 1rem;
    padding-left: 1rem;
}

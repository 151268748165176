.Pagination {
    padding-top: 4rem;

    @include mq($from: 1024px) {
        padding-top: 7rem;
    }

    @include mq($from: 1240px) {
        padding-top: 11rem;
    }
}

.Pagination-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.Pagination-item {
    margin: 0.125rem;
}

.Pagination-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    width: auto;
    height: 4rem;
    border: 2px solid transparent;
    border-radius: 4rem;
    color: $color-orange-primary;
    font-size: 1.4rem;
    line-height: 1;
    background-color: transparent;
    transition: color 0.2s, border 0.2s, background 0.2s;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        background-color: transparent;
        transform: translate(-50%, -50%);
        transition: background-color 200ms;
    }

    .Icon {
        width: 1em;
        height: 1em;
    }
}

.Pagination-link--current {
    width: 4rem;
    color: white !important;
    font-weight: 400;
    background-color: $color-orange-primary !important;

    span {
        position: relative;
        top: 2px;
    }
}

.Pagination-link--link:not(.Pagination-link--disabled),
.Pagination-link--prev:not(.Pagination-link--disabled),
.Pagination-link--next:not(.Pagination-link--disabled) {

    &:hover {
        color: $color-orange-primary;

        &::before {
            background-color: rgba($color-orange-primary, 0.2);
        }
    }
}

.Pagination-link--prev {

    .Icon {
        left: 0;
    }
}

.Pagination-link--next {

    .Icon {
        left: 0;
    }
}

.Pagination-link--disabled {
    opacity: 0.5;
}

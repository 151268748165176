.ProductBoxes {
    display: flex;
    flex-wrap: wrap;
    margin-top: -2rem;
    margin-left: -2rem;
}

.ProductBoxes-item {
    flex-basis: auto;
    padding-top: 2rem;
    padding-left: 2rem;
    width: 100%;

    @include mq($from: 480px) {
        width: calc(100% / 2);
    }

    @include mq($from: 768px) {
        width: calc(100% / 3);
    }

    @include mq($from: 920px) {
        width: calc(100% / 2);
    }

    @include mq($from: 1240px) {
        padding-top: 4rem;
        padding-left: 4rem;
        width: calc(100% / 3);
    }
}

.ProductBoxes--fullWidth {

    .ProductBoxes-item {
        width: 100%;

        @include mq($from: 480px) {
            width: calc(100% / 2);
        }

        @include mq($from: 768px) {
            width: calc(100% / 3);
        }

        @include mq($from: 1240px) {
            padding-top: 4rem;
            padding-left: 4rem;
            width: calc(100% / 4);
        }
    }
}

.ProductBox {
    --heading-color: #{$text-color};
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    height: 100%;
    border-radius: 1.5rem;
    color: $text-color;
    text-decoration: none;
    background-color: white;
    box-shadow: 0 4px 20px 10px rgba(black, 0.03);
    transition: box-shadow 200ms;

    &:hover,
    &:focus,
    &:active {
        color: $text-color;
        text-decoration: none;
        box-shadow: 0 4px 20px 10px rgba($color-orange-primary, 0.03);

        .ProductBox-imageAlt {
            opacity: 1;
        }
    }
}

.ProductBox-body {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    height: 100%;

    @include mq($from: 768px) {
        padding-top: 2rem;
        padding-right: 2rem;
        padding-bottom: 3rem;
        padding-left: 2rem;
    }
}

.ProductBox-title {
    color: $color-orange-primary;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4;
    transition: color 200ms;
    text-align: center;
    overflow: hidden;
    word-break: break-word;

    @include mq($from: 768px) {
        font-size: 1.8rem;
    }
}

.ProductBox-media {
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    border-radius: 1.5rem;
    overflow: hidden;
    background-color: white;

    &.without-image {
        padding-bottom: 100%;
    }
}

.ProductBox-image {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    background-color: white;

    .Media-object > img,
    .ImageWrapper > img {
        object-fit: contain;
    }

    @include mq($from: 580px) {
        padding-top: 5rem;
        padding-right: 5rem;
        padding-bottom: 5rem;
        padding-left: 5rem;
    }
}

.ProductBox-imageAlt {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: opacity 200ms;
}

.ProductBox-info {
    margin-top: auto;
    padding-top: 1rem;
    padding-left: 0;
    list-style: none;
    text-align: center;
}

.ProductBox-sku {
    font-size: 1.2rem;
    text-transform: uppercase;
}

.ProductBox-price {

    span {
        margin-right: 0.5rem;
        font-weight: 300;
        font-size: 1.2rem;
    }

    strong {
        color: $color-gray-700;
        font-weight: 500;
    }
}

.ProductBox-labels {
    position: absolute;
    top: 2rem;
    right: -1rem;
    z-index: 3;

    @include mq($from: 1240px) {
        right: -2rem;
    }
}

.ProductBox-label {
    display: block;
    padding-top: 0.55rem;
    padding-right: 0.75rem;
    padding-bottom: 0.65rem;
    padding-left: 0.75rem;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    background-color: $color-blue-primary;
}

.ProductBox-label--green {
    background-color: $color-green-primary;
}

.ProductBox-label--lime {
    background-color: $color-lime-primary;
}

.ProductBox-label--yellow {
    background-color: $color-yellow-primary;
}

.ProductBox-label--pink {
    background-color: $color-pink-primary;
}

.ProductBox-label--purple {
    background-color: $color-purple-primary;
}

.ProductBox-label--red {
    background-color: $color-red-primary;
}

.ProductBox-price-old {
    text-decoration: line-through;
}

.ProductBox-price-new {
    color: $color-orange-primary !important;
}

.HeaderTopLinks {
    color: white;
}

.HeaderTopLinks-item + .HeaderTopLinks-item {
    margin-left: 2rem;
}

.HeaderTopLinks-link {
    color: white;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: white;
        text-decoration: underline;
    }
}

.PricesToggle {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-left: 1rem;

    [type='checkbox'] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    @include mq($from: 640px) {
        margin-left: 2rem;
    }
}

.PricesToggle-label {
    order: -1;
    margin-right: 0.5rem;
    color: $color-orange-primary;
}

.PricesToggle-switch {
    position: relative;
    width: 3.5rem;
    height: 1.75rem;
    border-radius: 3.5rem;
    background-color: rgba($color-orange-primary, 0.3);
    transition: background-color 200ms;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0.4rem;
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        background-color: white;
        transition: 200ms;
        transform: translateY(-50%);
    }
}

.PricesToggle [type='checkbox']:checked + .PricesToggle-switch {
    background-color: $color-orange-primary;

    &::after {
        left: calc(100% - 0.4rem);
        transform: translate(-100%, -50%);
    }
}

.SiteOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    font-weight: 400;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        z-index: -2;
        width: 100%;
        height: 100vh;
        background-color: rgba(black, 0.9);
    }
}

.SiteOverlay--enter {
    transition: visibility 1000ms cubic-bezier(0.165, 0.85, 0.45, 1);
}

.SiteOverlay--leave {
    transition: visibility 500ms cubic-bezier(0.165, 0.85, 0.45, 1);
    transition-delay: 500ms;
}

.SiteOverlay--enter::before {
    transition: opacity 500ms cubic-bezier(1, 0, 0.35, 1);
}

.SiteOverlay--leave::before {
    transition: opacity 500ms cubic-bezier(1, 0, 0.35, 1);
    transition-delay: 500ms;
}

.SiteOverlay--enter-end,
.SiteOverlay--leave-start {
    visibility: visible;
    height: 100vh;

    &::before {
        opacity: 1;
    }
}

.SiteOverlay--enter-start,
.SiteOverlay--leave-end {
    height: 0;
    visibility: hidden;

    &::before {
        opacity: 0;
    }
}

.SiteOverlay-container {
    opacity: 1;
    display: flex;
    position: relative;
    z-index: 2;
    width: calc(100% - 6rem);
    max-width: 64rem;
    height: var(--vh);
    background-color: $color-orange-50;
}

.SiteOverlay--enter .SiteOverlay-container {
    transition: transform 750ms cubic-bezier(0.75, 0, 0.45, 0.90);
    transition-delay: 200ms;
}

.SiteOverlay--leave .SiteOverlay-container {
    transition: transform 750ms cubic-bezier(0.75, 0, 0.45, 0.90);
    transition-delay: 200ms;
}

.SiteOverlay--enter-start .SiteOverlay-container,
.SiteOverlay--leave-end .SiteOverlay-container {
    transform: translateX(-100%);
}

.SiteOverlay--enter-end .SiteOverlay-container,
.SiteOverlay--leave-start .SiteOverlay-container {
    transform: translateX(0);
}

.SiteOverlay-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    overflow-x: hidden;
    overflow-y: auto;

    @include mq($from: 375px) {
        padding-top: 3rem;
        padding-right: 3rem;
        padding-bottom: 3rem;
        padding-left: 3rem;
    }
}

.SiteOverlayToggle {
    @extend %button;
    position: absolute;
    top: 3rem;
    right: -4.65rem;
    padding: 0;
    border: 0;
    color: white;

    .Icon {
        width: 3rem;
        height: 3rem;
    }

    &:hover,
    &:focus,
    &:active {
        color: rgba(white, 0.75);
        box-shadow: none;
    }

    @include mq($from: 375px) {
        right: -5.125rem;

        .Icon {
            width: 4rem;
            height: 4rem;
        }
    }
}

.SiteOverlay--enter .SiteOverlay-inner {
    transition: opacity 500ms;
    transition-delay: 500ms;
}

.SiteOverlay--enter .SiteOverlayToggle {
    transition: opacity 500ms;
    transition-delay: 750ms;
}

.SiteOverlay--leave .SiteOverlay-inner {
    transition: opacity 500ms;
    transition-delay: 250ms;
}

.SiteOverlay--leave .SiteOverlayToggle {
    transition: opacity 500ms;
    transition-delay: 0;
}

.SiteOverlay--enter-start .SiteOverlay-inner,
.SiteOverlay--leave-end .SiteOverlay-inner,
.SiteOverlay--enter-start .SiteOverlayToggle,
.SiteOverlay--leave-end .SiteOverlayToggle {
    opacity: 0;
}

.SiteOverlay--enter-end .SiteOverlay-inner,
.SiteOverlay--leave-start .SiteOverlay-inner,
.SiteOverlay--enter-end .SiteOverlayToggle,
.SiteOverlay--leave-start .SiteOverlayToggle {
    opacity: 1;
}

.SiteOverlay-items {}

.SiteOverlay-item {
    position: relative;

    &:first-child {
        padding-top: 0;

        &::before {
            content: none;
        }
    }
}

.SiteOverlay-item {
    padding-top: 3rem;
    padding-bottom: 3rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -2rem;
        left: -2rem;
        border-top: 1px solid rgba($color-orange-primary, 0.1);
    }

    @include mq($from: 375px) {
        padding-top: 3rem;
        padding-bottom: 3rem;

        &::before {
            right: -3rem;
            left: -3rem;
        }
    }
}

.SiteOverlay-item--sm {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include mq($from: 375px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.SiteOverlay-item--xs {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include mq($from: 375px) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}

.cb-News {
    position: relative;
    overflow: hidden;
}

.cb-News-items {
    display: grid;
    gap: 2rem;

    @include mq($from: 480px) {
        gap: 3rem;
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: 768px) {
        gap: 3rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @include mq($from: 1240px) {
        gap: 5rem;
        grid-template-columns: repeat(4, 1fr);
    }
}

.cb-News-slider {
    opacity: 1;
    position: relative;
    transition: opacity 1000ms;

    &:not(.Slider--initialized) {
        opacity: 0;
        overflow: hidden;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: -15%;
        width: calc((100vw - 100% - 2rem) / 2);
        z-index: 2;
        height: 130%;
        background-color: white;
    }

    &::before {
        left: calc(((100vw - 100%) / -2) - 2rem);
    }

    &::after {
        right: calc(((100vw - 100%) / -2) - 2rem);
    }

    .Slider-item {
        height: auto;
    }
}

.cb-News-sliderButtons {
    display: none;
    position: absolute;
    top: -6.65rem;
    right: 0;

    > * + * {
        margin-left: 0.5rem;
    }

    @include mq($from: 768px) {
        display: flex;
    }

    @include mq($from: 1024px) {
        top: -9.25rem;
    }
}

.cb-News[data-slider-items='1'] {

    .cb-News-sliderButtons {
        display: none !important;
    }
}

.cb-News[data-slider-items='2'] {

    @include mq($from: 480px) {

        .cb-News-sliderButtons {
            display: none !important;
        }
    }
}

.cb-News[data-slider-items='3'] {

    @include mq($from: 768px) {

        .cb-News-sliderButtons {
            display: none !important;
        }
    }
}

.cb-News[data-slider-items='4'] {

    @include mq($from: 1240px) {

        .cb-News-sliderButtons {
            display: none !important;
        }
    }
}

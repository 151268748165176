// Formie
.fui-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit--md * -1);
    margin-left: u($spacing-unit * -1);

    > * {
        margin-top: u($spacing-unit--md);
        margin-left: u($spacing-unit);
    }

     .fui-page-container > &:not(.fui-row-empty) + & {
         padding-top: u($spacing-unit--md);
     }
}

.fui-row-empty {
    display: none;
}

.formie-recaptcha-placeholder {
    margin-top: u($spacing-unit--sm);
    margin-bottom: u($spacing-unit);
}

.fui-field {
    width: 100%;

    @include mq($from: $viewport--md) {
        flex: 1;
    }
}

.fui-label {
    @extend .Form-label;
}

.fui-input {
    @extend .Form-field;
    @extend .Form-field--input;

    .fui-type-multi-line-text & {
        @extend .Form-field--textarea;
    }
}

.fui-select {
    @extend .Form-field--select;
}

.fui-checkbox {
    @extend .Form-field;
    @extend .Form-field--checkbox;
}

.fui-radio {
    @extend .Form-field;
    @extend .Form-field--radio;
}

.fui-type-address {

    .fui-row + .fui-row {
        padding-top: 2rem;
    }
}

.fui-sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.fui-btn-container {
    margin-top: u($spacing-unit);
}

.fui-row-empty {

    .fui-field {
        padding-top: 0;
    }
}

// Alerts
.fui-alert {
    @extend .Alert;

    @each $modifier, $color in $theme-modifiers {
        $alert-color: color-level($color, 6);
        $alert-border: color-level($color, -9);

        &-#{$modifier} {
            color: $alert-color;
            background-color: color-level($color, -10);
            border-color: $alert-border;

            hr {
                border-bottom-color: darken($alert-border, 5%);
            }

            .Alert-link {
                color: darken($alert-color, 10%);
            }
        }
    }
}

.fui-type-html {}

.fui-error-message {
    margin-top: 1rem;
    color: $red;
    font-size: 1.3rem;
}

.Form-field--select.fui-error,
.Form-field--input.fui-error {
    border-color: $red;
}

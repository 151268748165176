.CheckoutSteps {
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.CheckoutSteps-list {
    counter-reset: checkoutSteps;
    margin-top: -2rem;
    margin-left: -2rem;
}

.CheckoutSteps-item {
    flex: 1 1 auto;
    padding-top: 2rem;
    padding-left: 2rem;
}

.CheckoutSteps-link {
    padding-top: 1.3rem;
    padding-right: 1rem;
    padding-bottom: 1.3rem;
    padding-left: 1rem;
    border-radius: 1.5rem;
    color: $text-color;
    background-color: $color-orange-50;
    text-align: center;

    > span::before {
        counter-increment: checkoutSteps;
        content: counter(checkoutSteps) '. ';
    }

    &.is-active {
        color: white;
        font-weight: 500;
        background-color: $color-orange-primary;
    }
}

.StoreHours {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: minmax(0, 2.5rem) minmax(0, 1fr);

    dt {
        padding-top: 0.225rem;
        color: $color-orange-primary;
        font-weight: 600;
        font-size: 1.3rem;
        text-transform: uppercase;
    }
}

/* Notification */
.Notification {
    display: none;
    padding-top: 1.5rem;

    &.is-active {
        display: block;
    }
}

.Notification-wrapper {
    position: relative;
    padding: 2rem 6rem 2rem 2rem;
    border-radius: 2rem;
    overflow: hidden;
    background-color: $color-blue-primary;
    color: $white;
    @include mq($from: $viewport--md) {
        padding: 2.5rem 6rem 2.5rem 3.5rem;
    }
}

.Notification-body {
    font-weight: 500;
    font-size: 1.6rem;
    a {
        color: $white;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

.Notification-close {
    position: absolute;
    top: 0;
    right: 2.5rem;
    height: 100%;
    width: 3rem;
    z-index: 1;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
}

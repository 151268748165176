.HeaderShop {
    margin-left: 1rem;
    color: $color-orange-primary;

    @include mq($from: 640px) {
        margin-left: 2rem;
    }

    // @include mq($from: 1240px) {
    //     margin-left: 4rem;
    // }
}

.HeaderShop-list {
    align-items: center;
}

.HeaderShop-item + .HeaderShop-item {
    margin-left: 1rem;
}

.HeaderShop-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    color: $color-orange-primary;
    text-decoration: none;

    .Icon {
        width: 3.5rem;
        height: 3.5rem;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: $color-blue-primary;
        text-decoration: none;
    }
}

.HeaderShop-label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.25rem;
    right: -0.5rem;
    width: 2rem;
    height: 2rem;
    border: 2px solid white;
    border-radius: 2rem;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1;
    background-color: $color-blue-primary;
}

.Box {
    --heading-color: #{$color-orange-primary};
    position: relative;
    padding-top: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    border-radius: 1.5rem;
    background-color: $color-orange-50;

    &.with-icon {
        padding-left: 10rem;

        .Box-icon {
            top: 3.5rem;
            left: 4rem;
        }

        .Box-icon--country {
            top: 4rem;
        }
    }
}

.Box--sm {
    padding-top: 2rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    padding-left: 3rem;

    &.with-icon {
        padding-left: 9rem;

        .Box-icon {
            top: 1.5rem;
            left: 3rem;
        }

        .Box-icon--country {
            top: 2rem;
        }
    }
}

.Box--primary {
    --heading-color: white;
    color: white;
    font-weight: 400;
    background-color: $color-orange-primary;

    a {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: inherit;
            text-decoration: none;
        }
    }
}

.Box-title {
    font-size: 1.8rem;
}

.Box-icon {
    position: absolute;
}

/* Colors: Custom */

// Grey
$cc-grey:                           #ccc !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);




///*
///: Grizo

///: Gray
$color-gray-50:                         hsla(210, 2%, 98%, 1);
$color-gray-100:                        hsla(210, 2%, 88%, 1);
$color-gray-200:                        hsla(210, 2%, 78%, 1);
$color-gray-300:                        hsla(210, 2%, 68%, 1);
$color-gray-400:                        hsla(210, 2%, 58%, 1);
$color-gray-500:                        hsla(210, 2%, 48%, 1);
$color-gray-600:                        hsla(210, 2%, 38%, 1);
$color-gray-700:                        hsla(210, 2%, 28%, 1);
$color-gray-800:                        hsla(210, 2%, 18%, 1);
$color-gray-900:                        hsla(210, 2%, 8%, 1);
$color-gray-primary:                    $color-gray-800;

///: Orange :: Primary
$color-orange-50:                       hsla(24, 87%, 98%, 1);
$color-orange-100:                      hsla(24, 87%, 88%, 1);
$color-orange-200:                      hsla(24, 87%, 78%, 1);
$color-orange-300:                      hsla(24, 87%, 68%, 1);
$color-orange-400:                      hsla(24, 87%, 58%, 1);
$color-orange-500:                      hsla(24, 87%, 48%, 1);
$color-orange-600:                      hsla(24, 87%, 38%, 1);
$color-orange-700:                      hsla(24, 87%, 28%, 1);
$color-orange-800:                      hsla(24, 87%, 18%, 1);
$color-orange-900:                      hsla(24, 87%, 8%, 1);
$color-orange-primary:                  $color-orange-500;

///: Blue :: Secondary
$color-blue-50:                         hsla(232, 81%, 97%, 1);
$color-blue-100:                        hsla(232, 81%, 87%, 1);
$color-blue-200:                        hsla(232, 81%, 77%, 1);
$color-blue-300:                        hsla(232, 81%, 67%, 1);
$color-blue-400:                        hsla(232, 81%, 57%, 1);
$color-blue-500:                        hsla(232, 81%, 47%, 1);
$color-blue-600:                        hsla(232, 81%, 37%, 1);
$color-blue-700:                        hsla(232, 81%, 27%, 1);
$color-blue-800:                        hsla(232, 81%, 17%, 1);
$color-blue-900:                        hsla(232, 81%, 7%, 1);
$color-blue-primary:                    $color-blue-600;

///: Brown :: Dogs
$color-brown-50:                        hsla(29, 72%, 94%, 1);
$color-brown-100:                       hsla(29, 72%, 84%, 1);
$color-brown-200:                       hsla(29, 72%, 74%, 1);
$color-brown-300:                       hsla(29, 72%, 64%, 1);
$color-brown-400:                       hsla(29, 72%, 54%, 1);
$color-brown-500:                       hsla(29, 72%, 44%, 1);
$color-brown-600:                       hsla(29, 72%, 34%, 1);
$color-brown-700:                       hsla(29, 72%, 24%, 1);
$color-brown-800:                       hsla(29, 72%, 14%, 1);
$color-brown-900:                       hsla(29, 72%, 4%, 1);
$color-brown-primary:                   $color-brown-700;

///: Red :: Cats
$color-red-50:                          hsla(356, 95%, 96%, 1);
$color-red-100:                         hsla(356, 95%, 86%, 1);
$color-red-200:                         hsla(356, 95%, 76%, 1);
$color-red-300:                         hsla(356, 95%, 66%, 1);
$color-red-400:                         hsla(356, 95%, 56%, 1);
$color-red-500:                         hsla(356, 95%, 46%, 1);
$color-red-600:                         hsla(356, 95%, 36%, 1);
$color-red-700:                         hsla(356, 95%, 26%, 1);
$color-red-800:                         hsla(356, 95%, 16%, 1);
$color-red-900:                         hsla(356, 95%, 6%, 1);
$color-red-primary:                     $color-red-500;

///: Yellow :: Farm Animals
$color-yellow-50:                       hsla(44, 100%, 99%, 1);
$color-yellow-100:                      hsla(44, 100%, 89%, 1);
$color-yellow-200:                      hsla(44, 100%, 79%, 1);
$color-yellow-300:                      hsla(44, 100%, 69%, 1);
$color-yellow-400:                      hsla(44, 100%, 59%, 1);
$color-yellow-500:                      hsla(44, 100%, 49%, 1);
$color-yellow-600:                      hsla(44, 100%, 39%, 1);
$color-yellow-700:                      hsla(44, 100%, 29%, 1);
$color-yellow-800:                      hsla(44, 100%, 19%, 1);
$color-yellow-900:                      hsla(44, 100%, 9%, 1);
$color-yellow-primary:                  $color-yellow-500;

///: Green :: Reptiles
$color-green-50:                        hsla(145, 100%, 99%, 1);
$color-green-100:                       hsla(145, 100%, 89%, 1);
$color-green-200:                       hsla(145, 100%, 79%, 1);
$color-green-300:                       hsla(145, 100%, 69%, 1);
$color-green-400:                       hsla(145, 100%, 59%, 1);
$color-green-500:                       hsla(145, 100%, 49%, 1);
$color-green-600:                       hsla(145, 100%, 39%, 1);
$color-green-700:                       hsla(145, 100%, 29%, 1);
$color-green-800:                       hsla(145, 100%, 19%, 1);
$color-green-900:                       hsla(145, 100%, 9%, 1);
$color-green-primary:                   $color-green-700;

///: Teal :: Pond
$color-teal-50:                         hsla(179, 100%, 94%, 1);
$color-teal-100:                        hsla(179, 100%, 84%, 1);
$color-teal-200:                        hsla(179, 100%, 74%, 1);
$color-teal-300:                        hsla(179, 100%, 64%, 1);
$color-teal-400:                        hsla(179, 100%, 54%, 1);
$color-teal-500:                        hsla(179, 100%, 44%, 1);
$color-teal-600:                        hsla(179, 100%, 34%, 1);
$color-teal-700:                        hsla(179, 100%, 24%, 1);
$color-teal-800:                        hsla(179, 100%, 14%, 1);
$color-teal-900:                        hsla(179, 100%, 4%, 1);
$color-teal-primary:                    $color-teal-600;

///: Pink :: Fish
$color-pink-50:                         hsla(327, 71%, 99%, 1);
$color-pink-100:                        hsla(327, 71%, 89%, 1);
$color-pink-200:                        hsla(327, 71%, 79%, 1);
$color-pink-300:                        hsla(327, 71%, 69%, 1);
$color-pink-400:                        hsla(327, 71%, 59%, 1);
$color-pink-500:                        hsla(327, 71%, 49%, 1);
$color-pink-600:                        hsla(327, 71%, 39%, 1);
$color-pink-700:                        hsla(327, 71%, 29%, 1);
$color-pink-800:                        hsla(327, 71%, 19%, 1);
$color-pink-900:                        hsla(327, 71%, 9%, 1);
$color-pink-primary:                    $color-pink-500;

///: Purple :: Birds
$color-purple-50:                       hsla(277, 59%, 92%, 1);
$color-purple-100:                      hsla(277, 59%, 82%, 1);
$color-purple-200:                      hsla(277, 59%, 72%, 1);
$color-purple-300:                      hsla(277, 59%, 62%, 1);
$color-purple-400:                      hsla(277, 59%, 52%, 1);
$color-purple-500:                      hsla(277, 59%, 42%, 1);
$color-purple-600:                      hsla(277, 59%, 32%, 1);
$color-purple-700:                      hsla(277, 59%, 22%, 1);
$color-purple-800:                      hsla(277, 59%, 12%, 1);
$color-purple-900:                      hsla(277, 59%, 2%, 1);
$color-purple-primary:                  $color-purple-600;

///: Lime :: Rodents
$color-lime-50:                         hsla(77, 73%, 94%, 1);
$color-lime-100:                        hsla(77, 73%, 84%, 1);
$color-lime-200:                        hsla(77, 73%, 74%, 1);
$color-lime-300:                        hsla(77, 73%, 64%, 1);
$color-lime-400:                        hsla(77, 73%, 54%, 1);
$color-lime-500:                        hsla(77, 73%, 44%, 1);
$color-lime-600:                        hsla(77, 73%, 34%, 1);
$color-lime-700:                        hsla(77, 73%, 24%, 1);
$color-lime-800:                        hsla(77, 73%, 14%, 1);
$color-lime-900:                        hsla(77, 73%, 4%, 1);
$color-lime-primary:                    $color-lime-500;

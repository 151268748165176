.FaqContent {}

.FaqContent--empty {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1em;
    border-top: 1px solid $color-gray-100;
    border-bottom: 1px solid $color-gray-100;
}

.FaqContent-title {
    margin-bottom: 2rem;
    color: $color-orange-primary;

    @include mq($from: 920px) {
        margin-bottom: 4rem;
        text-align: center;
    }
}

.FaqContent-items {
    border-bottom: 1px solid $color-gray-100;
}

.FaqContent-item {
    border-top: 1px solid $color-gray-100;

    &.is-active {
        background-color: rgba($color-orange-primary, 0.05);

        .FaqContent-question {
            color: $color-orange-primary;
            font-weight: 600;
        }
    }
}

.FaqContent-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1em;
    color: $text-color;
    font-size: 1.6rem;

    .Icon {
        top: 1px;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color-orange-primary;
    }

    @include mq($from: 920px) {
        padding-left: 3.5rem;
    }
}

.FaqContent-answer {
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    font-weight: 400;

    @include mq($from: 920px) {
        padding-left: 3.5rem;
    }
}

.FaqContent-actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;
    margin-left: -1rem;
}

.FaqContent-action {
    padding-top: 1rem;
    padding-left: 1rem;
}

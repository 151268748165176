.CustomSelect {
    position: relative;
    width: 100%;

    @include mq($from: 920px) {
        max-width: 24rem;
    }
}

.CustomSelect-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-right: 2rem;
    padding-bottom: 1.4rem;
    padding-left: 2rem;
    width: 100%;
    border-radius: 100px;
    color: $text-color;
    background-color: $color-gray-50;
}

.CustomSelect-buttonIcon {

    &.is-active {
        transform: rotate(-180deg);
    }
}

.CustomSelect-dropdown {
    position: absolute;
    top: 100%;
    z-index: 10;
    margin-top: 1rem;
}

.CustomSelect-dropdownItems {
    padding: 0;
    width: 100%;
    min-width: 24rem;
    border: 1px solid $color-gray-100;
    border-radius: 1.5rem;
    list-style: none;
    color: $text-color;
    background-color: $color-gray-50;
    box-shadow: 0 4px 20px 10px rgba(black, 0.03);
}

.CustomSelect-dropdownItem + .CustomSelect-dropdownItem {
    border-top: 1px solid $color-gray-100;
}

.CustomSelect-dropdownLabel {
    cursor: pointer;
    display: block;
    padding-top: 0.75rem;
    padding-right: 2rem;
    padding-bottom: 0.65rem;
    padding-left: 2rem;
    transition: color 200ms;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: $color-orange-primary;
    }
}

.CustomSelect-dropdown--right {

    @include mq($from: 920px) {
        right: 0;
    }
}

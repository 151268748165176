.SimpleCta {
    margin-right: auto;
    margin-left: auto;
    padding-top: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    max-width: 78rem;
    border-radius: 1.5rem;
    color: white;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.3;
    text-align: center;
    background-color: $color-orange-primary;

    @include mq($from: 1024px) {
        padding-top: 6rem;
        padding-right: 4rem;
        padding-bottom: 6rem;
        padding-left: 4rem;
        font-size: 2.15rem;
    }

    @include mq($from: 1240px) {
        padding-top: 8rem;
        padding-right: 4rem;
        padding-bottom: 8rem;
        padding-left: 4rem;
        font-size: 2.5rem;
    }
}

.SimpleContent-inner.with-cta + .SimpleCta {
    margin-top: -6rem;

    @include mq($from: 1024px) {
        margin-top: -10rem;
    }

    @include mq($from: 1240px) {
        margin-top: -14rem;
    }
}

.SimpleCta-inner {
    margin-right: auto;
    margin-left: auto;
    max-width: 40rem;
}

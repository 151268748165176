.Chips {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: -0.5rem;
    margin-left: -0.5rem;

    > * {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
    }
}

.Chip {
    @extend %button !optional;
    cursor: default !important;
    display: inline-flex;
    align-items: flex-start;
    position: relative;
    padding-top: 0.95rem;
    padding-right: 1.5rem;
    padding-bottom: 0.95rem;
    padding-left: 1.5rem;
    border: 1px solid currentColor;
    border-radius: 100px;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1;
    text-align: left;
    transition: border 200ms, color 200ms, background 200ms;

    &.is-clickable {
        cursor: pointer !important;
        padding-right: 3rem;
    }
}

.Chip--primary:not(.Chip--outline) {
    color: white;
    background-color: $color-orange-primary;

    &:focus,
    &:hover,
    &:active {
        color: white;
        background-color: $color-orange-primary;
    }

    &.is-clickable {

        &:focus,
        &:hover,
        &:active {
            color: white;
            background-color: $color-orange-600;
        }
    }
}

.Chip--primary.Chip--outline {
    color: $color-orange-primary;

    &:focus,
    &:hover,
    &:active {
        color: $color-orange-primary;
    }

    &.is-clickable {

        &:focus,
        &:hover,
        &:active {
            color: $color-orange-600;
        }
    }
}

.Chip-icon {
    opacity: 0.75;
    position: absolute;
    top: 7px;
    right: 1.25rem;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 1.5rem;
    font-family: sans-serif;
    line-height: 1;
}

.SiteOverlayCategories {
    margin-top: -1rem;
    margin-left: -2rem;
    color: $color-orange-primary;
    font-size: 1.2rem;

    @include mq($from: 375px) {
        margin-left: -3rem;
        font-size: 1.3rem;
    }

    @include mq($from: 640px) {
        font-size: 1.5rem;
    }
}

.SiteOverlayCategories-item {
    flex: 0 1 auto;
    padding-top: 1rem;
    padding-left: 2rem;
    width: 50%;

    @include mq($from: 375px) {
        padding-left: 3rem;
    }

    @include mq($from: 640px) {
        width: 25%;
    }
}

.SiteOverlayCategories-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--product-category-color, #{$color-orange-primary});
    text-decoration: none;

    &.without-icon {
        justify-content: flex-end;
        height: 100%;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: white;
        text-decoration: none;

        .SiteOverlayCategories-iconWrapper {
            background-color: var(--product-category-color, #{$color-orange-primary});
        }

        .SiteOverlayCategories-label {
            color: $color-orange-600;
        }
    }
}

.SiteOverlayCategories-iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    width: 100%;
    border-radius: 15px;
    background-color: rgba($color-orange-primary, 0.05);
    transition: background-color 200ms;
}

.SiteOverlayCategories-icon {
    opacity: 1;
    fill: currentColor;
    width: 6rem;
    height: 6rem;

    @include mq($from: 375px) {
        width: 7rem;
        height: 7rem;
    }
}

.SiteOverlayCategories-label {
    display: block;
    margin-top: 0.5rem;
    color: $color-orange-primary;
    letter-spacing: 0.05em;
    transition: color 200ms;
}

.VideoWrapper {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;

    > video {
        width: 100%;
    }

    .plyr--video {
        --plyr-color-main: #{$color-orange-primary};
    }

    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded=true] {
        color: white;
        background-color: $color-orange-primary;
    }

    .plyr__control.plyr__control--overlaid {
        padding: calc(var(--plyr-control-spacing, 10px) * 2);
        color: white;
        background-color: $color-orange-primary;

        svg {
            width: 2rem;
            height: 2rem;
        }

        &:hover {
            color: white;
            background-color: $color-orange-600;
        }
    }

    .plyr--video.plyr--paused {

        .plyr__controls {
            display: none;
        }
    }

    .plyr__poster {
        background-size: cover;
    }
}

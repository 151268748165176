.Stock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .25rem .75rem;
}

.Stock.Stock--green .Stock-icon {
    background-color: $color-green-500;
}

.Stock.Stock--yellow .Stock-icon {
    background-color: $color-yellow-400;
}

.Stock.Stock--orange .Stock-icon {
    background-color: $color-orange-400;
}

.Stock.Stock--red .Stock-icon {
    background-color: $color-red-500;
}

.Stock-icon {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    flex-shrink: 0;
    border-radius: 1.4rem;
    box-shadow: 0 0 4px rgba(black, 0.08);
}

.Stock-label {

}

.Stock-tooltip {
    display: none;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 1rem;
    box-shadow: 3px 3px 12px rgba(black, 0.1);
    padding: 1rem 2rem;
    min-width: 15rem;
    font-size: 1.3rem;
    text-align: center;
    margin-top: 1rem;

    &::before {
        content: '';
        position: absolute;
        top: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-color: transparent transparent white transparent;
    }
}

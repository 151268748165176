.OrderHistory {
    display: flex;
    flex-direction: column;
}

.OrderHistory-row {
    display: grid;
    row-gap: 0.5rem;
    column-gap: 2rem;
    grid-template-columns: 1fr 4rem;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    border-radius: 1.5rem;
    color: $color-gray-800;
    background-color: $color-orange-50;

    @include mq($from: 520px) {
        grid-template-columns: repeat(6, 1fr) 4rem;
    }

    @include mq($from: 768px) {
        grid-template-columns: 1fr 2fr 1fr 1fr 1fr 4rem;
    }
}

.OrderHistory-row--header {
    padding-bottom: 1rem;
    color: $color-orange-primary;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: transparent;

    @include mq($from: 1024px) {
        font-size: 1.6rem;
    }

    @include mq($until: 767px) {
        display: none;

        .OrderHistory-column--qty,
        .OrderHistory-column--price,
        .OrderHistory-column--subtotal {
            display: none;
        }
    }
}

.OrderHistory-column--code {

    @include mq($until: 519px) {
        grid-column: 1 / span 1;
    }

    @include mq($from: 520px, $until: 767px) {
        grid-column: 1 / span 6;
    }
}

.OrderHistory-column--title {
    padding-right: 2rem;

    @include mq($until: 519px) {
        grid-column: 1 / span 1;
        padding-right: 0;
    }

    @include mq($from: 520px, $until: 767px) {
        grid-column: 1 / span 6;
        padding-right: 0;
    }
}

.OrderHistory-column--qty {

    @include mq($until: 519px) {
        grid-column: 1 / span 1;
    }

    @include mq($from: 520px, $until: 767px) {
        grid-column: 1 / span 2;
    }
}

.OrderHistory-column--price {

    @include mq($until: 519px) {
        grid-column: 1 / span 1;
    }

    @include mq($from: 520px, $until: 767px) {
        grid-column: 3 / span 2;
    }
}

.OrderHistory-column--subtotal {

    @include mq($until: 519px) {
        grid-column: 1 / span 1;
    }

    @include mq($from: 520px, $until: 767px) {
        grid-column: 5 / span 2;
    }
}

.OrderHistory-column--action {

    @include mq($until: 519px) {
        grid-column: 2;
        grid-row: 1 / span 5;
    }

    @include mq($from: 520px, $until: 767px) {
        grid-column: 7;
        grid-row: 1 / span 2;
    }
}

.OrderHistory-button {
    @extend %button !optional;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 4rem;
    height: 4rem;
    border: 1px solid rgba($color-orange-primary, 0.3);
    color: $color-orange-primary;

    &:hover,
    &:focus,
    &:active {
        color: white;
        border-color: $color-orange-primary;
        background-color: $color-orange-primary;
    }
}

.OrderHistory-buttonWrapper {
    position: relative;
}

.OrderHistory-buttonTooltip {
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0.5rem;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    border-radius: 1rem;
    color: $color-orange-primary;
    font-size: 1.2rem;
    text-align: center;
    white-space: nowrap;
    background-color: white;
    box-shadow: 0 4px 20px 10px rgba(black, 0.03);
}

.OrderHistory-mobileLabel {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(black, 0.1);
    font-weight: 500;
    font-size: 1.4rem;

    @include mq($from: 768px) {
        display: none;
    }
}

.OrderHistory-actions {
    order: -1;
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;
    margin-bottom: 4rem;
    margin-left: -1rem;

    > * {
        padding-top: 1rem;
        padding-left: 1rem;
    }
}

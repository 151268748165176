// .FooterLegalLinks {
//     margin-right: 2rem;

//     @include mq($from: 1024px) {
//         margin-right: 3rem;
//     }
// }

.FooterLegalLinks-list {
    flex-wrap: wrap;

    @include mq($until: 919px) {
        justify-content: center;
    }
}

.FooterLegalLinks-item {
    margin-right: 1rem;
    margin-left: 1rem;

    @include mq($from: 920px) {
        margin-right: 2rem;
        margin-left: 0;
    }

    @include mq($from: 1024px) {
        margin-right: 3rem;
        margin-left: 0;
    }
}

.FooterLegalLinks-link {
    display: inline-flex;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: inherit;
        text-decoration: underline;
    }
}

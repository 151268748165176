.Catalog {
    @include mq($from: 1024px) {

        .Intro {
            margin-bottom: 5rem;
        }
    }
}

.Catalog-items {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: 1024px) {
        row-gap: 4rem;
        grid-template-columns: repeat(4, 1fr);
    }
}

.Catalog-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2.5rem;
    overflow: hidden;
    color: var(--product-category-color, #{$color-orange-primary});
    font-weight: 500;
    font-size: 1.4rem;
    text-decoration: none;
    background-color: $color-blue-50;

    &:hover,
    &:focus,
    &:active {
        color: white;
        text-decoration: none;
        background-color: var(--product-category-color, #{$color-orange-primary});
    }

    @include mq($from: 1024px) {
        font-size: 1.8rem
    }

    @include mq($from: 1240px) {
        font-size: 2.5rem
    }
}

.Catalog-iconWrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include mq($from: 1024px) {
        padding: 0;
    }
}

.Catalog-icon {
    fill: currentColor;
    width: 8rem;
    height: 8rem;

    @include mq($from: 1024px) {
        width: 16rem;
        height: 16rem;
    }
}

.Catalog-label {
    padding-top: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    border-top-right-radius: 2.5rem;
    border-top-left-radius: 2.5rem;
    width: 100%;
    color: white;
    text-align: center;
    background-color: var(--product-category-color, #{$color-orange-primary});

    @include mq($from: 1024px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.Backdrop {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 60;
    width: 100%;
    height: 100%;
    background-color: rgba(black, var(--opacity));
}

.with-transition.Backdrop--enter {
    transition: visibility 600ms cubic-bezier(0.165, 0.85, 0.45, 1), opacity 300ms cubic-bezier(1, 0, 0.35, 1);
    transition-delay: 300ms;
}

.with-transition.Backdrop--leave {
    transition: visibility 300ms cubic-bezier(0.165, 0.85, 0.45, 1), opacity 300ms cubic-bezier(1, 0, 0.35, 1);
}

.with-transition.Backdrop--enter-end,
.with-transition.Backdrop--leave-start {
    visibility: visible;
    opacity: 1;
}

.with-transition.Backdrop--enter-start,
.with-transition.Backdrop--leave-end {
    visibility: hidden;
    opacity: 0;
}

.Banner + .SimpleContent {
    margin-top: -2rem;

    @include mq($from: 1024px) {
        margin-top: -6rem;
    }

    @include mq($from: 1240px) {
        margin-top: -10rem;
    }
}

.SimpleContent {
    position: relative;
    z-index: 1;
    padding-bottom: 4rem;

    > .Container {
        max-width: 110rem;
    }

    @include mq($from: 1024px) {
        padding-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        padding-bottom: 11rem;
    }
}

.SimpleContent-inner {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem;
    border-radius: 1.5rem;
    background-color: white;
    box-shadow:
        0px 1.4px 2.2px rgba(0, 0, 0, 0.006),
        0px 3.3px 5.3px rgba(0, 0, 0, 0.008),
        0px 6.3px 10px rgba(0, 0, 0, 0.01),
        0px 11.2px 17.9px rgba(0, 0, 0, 0.012),
        0px 20.9px 33.4px rgba(0, 0, 0, 0.014),
        0px 50px 80px rgba(0, 0, 0, 0.02)
    ;

    &.with-cta {
        padding-bottom: 10rem;
    }

    @include mq($from: 1024px) {
        padding-top: 5rem;
        padding-right: 8rem;
        padding-bottom: 5rem;
        padding-left: 8rem;

        &.with-cta {
            padding-bottom: 15rem;
        }
    }

    @include mq($from: 1240px) {
        padding-top: 6rem;
        padding-right: 12rem;
        padding-bottom: 6rem;
        padding-left: 12rem;

        &.with-cta {
            padding-bottom: 20rem;
        }
    }
}

.SimpleContent-intro {
    position: relative;
    text-align: center;

    &::after {
        content: '';
        display: block;
        margin-top: 4rem;
        margin-right: auto;
        margin-left: auto;
        width: 4rem;
        border-bottom: 1px solid $color-orange-primary;
    }
}

.SimpleContent-meta {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    padding-left: 0;
    list-style: none;
    color: $color-orange-primary;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;

    > li {
        margin: 0;
    }

    > li:not(:last-child) {
        position: relative;

        &::after {
            content: '•';
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }

    @include mq($until: 1024px) {
        font-size: 1.3rem;
    }

    @include mq($until: 768px) {
        font-size: 1.2rem;
    }
}

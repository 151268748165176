/* Components: Button */

// Namespace
$button-namespace:                    $namespace !default;

// Padding
$button-padding--t:                   1.1rem !default;
$button-padding--r:                   2.5rem !default;
$button-padding--b:                   $button-padding--t !default;
$button-padding--l:                   $button-padding--r !default;

// Typography
$button-font-stack:                   $font-grizo !default;
$button-font-weight:                  500 !default;
$button-line-height:                  inherit !default;
$button-font-size:                    13 !default;
$button-text-align:                   center !default;

// Borders
$button-border-width:                 1 !default;
$button-border-radius:                100 !default;

// States
$button-focus-width:                  0.2rem !default;
$button-focus-color:                  rgba($blue, 0.25) !default;
$button-focus-shadow:                 0 0 0 $button-focus-width $button-focus-color !default;

// Transition
$button-transition:                   (background-color, border-color, box-shadow, color) !default;

// Disabled
$button-disabled-opacity:             60% !default;

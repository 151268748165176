.FilterWrapper {}

.FilterWrapper-inner {
    position: relative;

    @include mq($until: 919px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 120;
        padding-top: 5rem;
        padding-right: 2rem;
        padding-bottom: 5rem;
        padding-left: 2rem;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        color: $text-color;
        background-color: white;
        transition: opacity .2s;

        &:not(.is-active) {
            display: none;
        }
    }
}

.FilterWrapper-close {
    @extend %button !optional;
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 0;
    color: white;
    background-color: $color-blue-primary;

    &:hover,
    &:focus,
    &:active {
        color: white;
        background-color: $color-blue-800;
    }

    &.is-active {
        display: flex;
    }
}

.ProductOverview {
    @include mq($from: 1024px) {

        .Intro {
            margin-bottom: 5rem;
        }
    }
}

.ProductOverview-items {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    @include mq($from: 1024px) {
        row-gap: 4rem;
        grid-template-columns: repeat(4, 1fr);
    }
}

.ProductOverview-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-gray-900;
    font-weight: 500;
    font-size: 1.6rem;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: var(--product-category-color, #{$color-orange-primary});

        .ProductOverview-imageWrapper .Media-object,
        .ProductOverview-imageWrapper .ImageWrapper {
            opacity: 0.75;
        }

        .ProductOverview-mediaIcon {
            opacity: 1;
        }
    }

    @include mq($from: 1024px) {
        font-size: 1.8rem
    }
}

.ProductOverview-imageWrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    border-radius: 2.5rem;
    background-color: var(--product-category-color, #{$color-orange-primary});
    box-shadow: 0 4px 20px 10px rgba(black, 0.03);

    .Media-object,
    .ImageWrapper {
        transition: opacity 200ms;
    }

    &.without-image {
        padding-bottom: percentage(2 / 3);
    }
}

.ProductOverview-label {
    padding-top: 2rem;
}

.ProductOverview-mediaIcon {
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    color: var(--product-category-color, #{$color-orange-primary});
    background-color: white;
    transform: translate(-50%, -50%);
    transition: opacity 200ms;
}

.ActiveFilters {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    border-radius: 1.5rem;
    background-color: $color-gray-50;

    @include mq($from: 920px) {
        flex-wrap: nowrap;
        padding-top: 2rem;
        padding-right: 3.5rem;
        padding-bottom: 2rem;
        padding-left: 3.5rem;
    }
}

.ActiveFilters-item {}

.ActiveFilters-item--right {
    margin-left: auto;
}

.ActiveFilters-item--fill {
    flex-grow: 1;

    @include mq($until: 919px) {
        order: 3;
        margin-top: 1rem;
        width: 100%;
    }
}

.ActiveFilters-item--full {
    width: 100%;
}

.ActiveFilters-item--group {
    display: flex;
}

.ActiveFilters-item--noShrink {
    flex-shrink: 0;
}

.ActiveFilters-label {
    flex-shrink: 0;
    position: relative;
    top: 4px;
    margin-right: 1rem;
    color: $color-orange-primary;
    font-weight: 400;
    font-size: 1.4rem;

    @include mq($from: 920px) {
        top: 6px;
    }
}

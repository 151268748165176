.Checkout {
    margin-bottom: 4rem;

    @include mq($from: 1024px) {
        margin-bottom: 7rem;
    }

    @include mq($from: 1240px) {
        margin-bottom: 11rem;
    }
}

.Checkout-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;

    @include mq($until: 519px) {

        > * + * {
            margin-bottom: 2rem;
        }
    }

    @include mq($from: 520px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.Checkout-action--next {

    @include mq($until: 519px) {
        order: -1;
    }
}

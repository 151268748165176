.SiteOverlayLinks {
    color: $text-color;
    font-size: 1.4rem;

    @include mq($from: 375px) {
        font-size: 1.6rem;
    }
}

.SiteOverlayLinks-list {
    flex-direction: column;
}

.SiteOverlayLinks-item {
    position: relative;
}

.SiteOverlayLinks-item + .SiteOverlayLinks-item {
    margin-top: 1.5rem;
    padding-top: 1.5rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -2rem;
        left: -2rem;
        border-top: 1px solid rgba($color-orange-primary, 0.1);
    }

    @include mq($from: 375px) {

        &::before {
            right: -3rem;
            left: -3rem;
        }
    }
}

.SiteOverlayLinks-link {
    color: $text-color;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: $text-color;
        text-decoration: underline;
    }
}

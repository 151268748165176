.Modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        z-index: -2;
        width: 100%;
        height: 100vh;
        background-color: rgba(black, 0.6);
    }
}

.Modal-container {
    position: relative;
    z-index: 1;
    padding-top: 4.8rem;
    padding-right: 1rem;
    padding-bottom: 4.8rem;
    padding-left: 1rem;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @include mq($from: 640px) {
        padding-top: 10%;
        padding-right: 5%;
        padding-bottom: 5%;
        padding-left: 5%;
    }
}

.Modal-inner {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    width: 100%;
    max-width: 70rem;
    background-color: white;
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07)
    ;

    @include mq($from: 640px) {
        padding-top: 3rem;
        padding-right: 3rem;
        padding-bottom: 3rem;
        padding-left: 3rem;
    }

    @include mq($from: 920px) {
        padding-top: 4rem;
        padding-right: 4rem;
        padding-bottom: 4rem;
        padding-left: 4rem;
    }
}

.Modal-close {
    @extend %button !optional;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -3.2rem;
    right: 0;
    z-index: 1;
    padding: 0;
    width: 3.2rem;
    height: 3.2rem;
    border: 0;
    border-radius: 0;
    color: white;
    background-color: $color-orange-primary;

    .Icon {
        width: 2.4rem;
        height: 2.4rem;
    }

    &:hover,
    &:active,
    &:focus {
        color: white;
        background-color: $color-blue-primary;
    }

    @include mq($from: 920px) {
        top: 0;
        right: -3.2rem;
    }
}

.Modal--enter {
    transition: visibility 1000ms cubic-bezier(0.165, 0.85, 0.45, 1);
}

.Modal--leave {
    transition: visibility 500ms cubic-bezier(0.165, 0.85, 0.45, 1);
    transition-delay: 500ms;
}

.Modal--enter::before {
    transition: opacity 500ms cubic-bezier(1, 0, 0.35, 1);
}

.Modal--leave::before {
    transition: opacity 500ms cubic-bezier(1, 0, 0.35, 1);
    transition-delay: 500ms;
}

.Modal--enter-end,
.Modal--leave-start {
    visibility: visible;

    &::before {
        opacity: 1;
    }
}

.Modal--enter-start,
.Modal--leave-end {
    visibility: hidden;

    &::before {
        opacity: 0;
    }
}

.Modal--enter .Modal-container {
    transition: opacity 500ms;
    transition-delay: 500ms;
}

.Modal--enter .Modal-close {
    transition: opacity 500ms;
    transition-delay: 750ms;
}

.Modal--leave .Modal-container {
    transition: opacity 500ms;
    transition-delay: 250ms;
}

.Modal--leave .Modal-close {
    transition: opacity 500ms;
    transition-delay: 0;
}

.Modal--enter-start .Modal-container,
.Modal--leave-end .Modal-container,
.Modal--enter-start .Modal-close,
.Modal--leave-end .Modal-close {
    opacity: 0;
}

.Modal--enter-end .Modal-container,
.Modal--leave-start .Modal-container,
.Modal--enter-end .Modal-close,
.Modal--leave-start .Modal-close {
    opacity: 1;
}
